import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CommoditiesService {
  httpPostOptions = {
    withCredentials: true
  }
  constructor(private config: Config,private _http: HttpClient) {
  }

  getCommodities(){
    return this._http.get(this.config.restCommodities + '?limit=-1',this.httpPostOptions)
      .toPromise()
  }
}
