import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Config} from "../config/config";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";

/**
 * Auth service
 */
@Injectable({providedIn: 'root'})
export class AuthService {

  httpPostOptions = {
    withCredentials: true,
  };

  constructor(private http: HttpClient, private config: Config,private router: Router) {
  }

  /**
   * Login API
   * @param email
   * @param password
   */
  login(email: string, password: string) {
    return this.http.post(
      this.config.restAuthPathLogin,
      {
        email: email,
        password: password
      },
      this.httpPostOptions
    );
  }

  /**
   * Logoff API
   */
  logoff() {
    localStorage.setItem(this.config.localStorage['account'], '');
    this.http.get(
      this.config.restAuthPathLogoff,
      this.httpPostOptions
    ).subscribe((res) => {
      if (res['success'] === true) {
        localStorage.removeItem(this.config.localStorage['account']);
        this.router.navigate(['/auth'])
      }
    });
  }

  /**
   * Check authorization
   */
  checkAuth() {
    return this.http.get(
      this.config.restAuthPathNoop,
      this.httpPostOptions
    ).toPromise()
  }

  /**
   * Get account session
   */
  getAccountSession() {
    return this.http.get(
      this.config.restAccountSession,
      this.httpPostOptions
    ).toPromise()
  }

  /**
   * Account data
   * @param id
   */
  accounts(id=0) {
    if(id !== 0){
      return this.http.get(
        this.config.restAccounts + '/' +id,
        this.httpPostOptions
      ).toPromise()
    }

  }
}
