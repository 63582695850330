import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) { }

  getVehicles(){
    //only not soft deleted vehicle
    let filter = '?filter=[{"property":"deleted","value":"false"}]&limit=-1';

    return this._http.get(this.config.restVehicle + filter, this.httpPostOptions)
      .toPromise()
  }
}
