import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";
import {shareReplay, map} from 'rxjs/operators';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CustomerSpecificationsService {
  //set header
  httpPostOptions = {
    withCredentials: true
  };

  constructor(private config: Config,private _http:HttpClient) {

  }

  getCustomerSpecifationsByCustomerId(customerId){
    let query = 'filter=[{"property":"customer_id","value":'+ customerId +'}]&limit=-1';

    return this._http.get(
      this.config.restCustomerSpecifications + '/?' + query ,
      this.httpPostOptions)
      .toPromise()
  }

}
