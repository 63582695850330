import {ActivatedRoute} from "@angular/router";
import {CookieService} from "angular2-cookie/core";
import {Component} from "@angular/core";
@Component({
  selector: 'app-config',
  template: '',
})
export class Config {
  defaultFilter: any =[];

  mainUrl: string;
  restPath: string;
  restAuthPathLogin: string;
  pathSeperator: string = '/';
  restAuthPathLogoff: string;
  restAuthPathNoop: string;
  restOrders: string;
  restCustomers: string;
  restOrderCollis: string;
  restProfiles: string;
  restWares: string;
  restCountries: string;
  restVehicle: string;
  restOrdergroups: string;
  restDepartments: string;
  restLangComponentFormsEditOrder: string;
  restLangComponentGridsOrder: string;
  restCustomerSpecifications: string;
  restCommodities: string;
  restAddress: string;
  restAccounts: string;
  localStorage = [];
  defaultLanguage: string;
  restUnitConversion: string;
  tpSendOrderToCar:string;
  tpRemoveOrderFromCar:string;
  restAccountSession: string;

  constructor(private route: ActivatedRoute,private cookie:CookieService) {
    let apiUrl = window.document.location.origin;

    this.defaultFilter['startDateSubtractDays'] = 200;
    this.defaultFilter['endDateAdditionDays'] = 60;
    this.defaultFilter['limit'] = 1500;

    //server settings
    let server= document.location.search.replace('?','');
    let paramsPairs= server.split('&');
    let urlParams={};
    if(paramsPairs.length > 0){
      paramsPairs.forEach(function (item) {
        urlParams[item.split('=')[0]]=item.split('=')[1];
      })
    }

    //set environment
    switch (origin) {
      case 'http://localhost:4200':
        apiUrl = 'http://localhost';
        break;
      case 'https://staging.tablet.truckplanner.dk':
        apiUrl = 'https://staging.truckplanner.dk';
        break;
      case 'https://tablet.eigiljensen.dk':
        apiUrl = 'https://tp.eigiljensen.dk';
        break;
      case 'https://tablet.eij.truckplanner.com':
        apiUrl = 'https://eij.truckplanner.com';
        break;
      case 'https://phuc.logiteam.dk':
        apiUrl = 'https://phuc.logiteam.dk';
        break;
      case 'http://phv.logiteam.dk:4200':
        apiUrl = 'http://phuc.logiteam.dk';
        break;
      case 'https://web.truckplanner.com':
        apiUrl = 'https://staging.truckplanner.com';
        break;
      case 'https://tablet.dev-vn.logiteam.dk':
        apiUrl = 'https://dev-vn.logiteam.dk';
        break;
      default:
        apiUrl = 'https://login.truckplanner.dk';
        break;
    }
    console.info("######################################");
    console.info('# APP host: ', origin);
    console.info('# API host: ', apiUrl);
    console.info("######################################");
    console.info('Search filter defaults');
    for (let key in this.defaultFilter) {
      if(this.defaultFilter.hasOwnProperty(key)){
        console.info('# ' +  key,  this.defaultFilter[key])
      }
    }
    console.info("######################################");
    //get the origin host url
    this.mainUrl = apiUrl;
    this.restPath = 'tabletv1';
    this.pathSeperator = '/';
    this.defaultLanguage = 'da';


    //local storage
    this.localStorage['account'] = 'tpTabletLocalStorage_account';

    let restFullPath = this.mainUrl + this.pathSeperator + this.restPath + this.pathSeperator;

    //session login path
    this.restAuthPathLogin = restFullPath + 'accountsession/login';
    //session login path
    this.restAuthPathLogoff = restFullPath + 'accountsession/logout';
    //session login path
    this.restAuthPathNoop = restFullPath + 'accountsession/noop';
    /***************
     * Language API path
     ***************/
    this.restLangComponentFormsEditOrder = '/assets/languages/components/forms/edit-order.lang.json';
    this.restLangComponentGridsOrder ='/assets/languages/components/grids/orders-tab.lang.json';

    //api order
    this.restAccountSession  = restFullPath + 'accountsession';
    this.restOrders = restFullPath + 'orders';
    this.restCustomers = restFullPath + 'customers';
    this.restProfiles = restFullPath + 'profiles';
    this.restOrderCollis = restFullPath + 'ordercollis';
    this.restWares = restFullPath + 'wares';
    this.restCountries = restFullPath + 'countries';
    this.restVehicle = restFullPath + 'vehicle';
    this.restOrdergroups = restFullPath + 'ordergroups';
    this.restDepartments = restFullPath + 'departments';
    this.restCustomerSpecifications = restFullPath + 'customerspecifications';
    this.restCommodities = restFullPath + 'commodities';
    this.restAddress = restFullPath + 'address';
    this.restAccounts =  this.mainUrl + this.pathSeperator + 'accounts';
    this.restUnitConversion = restFullPath + 'unitsconversions';
    //tp api
    this.tpSendOrderToCar =   this.mainUrl + '/bookings/orderactions/sendordertocar';
    this.tpRemoveOrderFromCar =   this.mainUrl + '/bookings/orderactions/removeorderfromcar';
  }

  storage(){

  }

}
