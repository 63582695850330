import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Config} from "../../config/config";
import {Router} from "@angular/router";

@Component({
    selector: 'app-auth',
    templateUrl: 'auth.component.html',
    styleUrls: ['auth.component.css']
})
export class AuthComponent implements OnInit {

    email: string;

    constructor(private authService: AuthService,private router: Router, private config: Config) {
    }

    ngOnInit() {
    }

    onSubmit(form: NgForm) {
        const email = form.value.email;
        const password = form.value.password;

        this.authService.login(email,password)
            .subscribe(
                resData => {
                  if(resData['success']=== false){
                    alert('Credentials are invalid, please try again')
                    localStorage.setItem(this.config.localStorage['account'], '');
                  } else {
                    //console.log(resData)
                    //save account to local storage
                    localStorage.setItem(this.config.localStorage['account'], JSON.stringify(resData['data']));
                    this.router.navigate(['/loader'])
                  }

                },
                error => {
                     localStorage.setItem(this.config.localStorage['account'], '');
                     console.log(error)
                     this.router.navigate(['/'])
                }
            )

    }

    login() {

    }

    logooff() {


    }

}
