import {Config} from "../config/config";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  //cache observable
  private cacheOrders$: Observable<any>;
  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) {}

  /**
   * Get all Orders
   */
  getProfileById(id,removeCache= false) {
    //if cached, get it
    if (!this.cacheOrders$ || removeCache === true) {
      this.cacheOrders$ = this.getProfileByIdHelper(id).pipe(
        shareReplay(1)
      );
    }
    return this.cacheOrders$;
  }


  getProfileByIdHelper(id) {
    return this._http.get(
      this.config.restProfiles + '/' + id,
      this.httpPostOptions
    ).pipe(
      map(res=> res)
    )
  }

  /**
   * get profile with promise
   */
  getProfiles() {
    return this._http.get(
      this.config.restProfiles,
      this.httpPostOptions)
      .toPromise()
  }


}
