import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public appData = []

  add(key,data) {
    this.appData[key] = data;
  }

  getItems() {
    return this.appData;
  }

  clearCart() {
    this.appData = [];
    return this.appData;
  }
}
