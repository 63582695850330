import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) { }

  /**
   * Get order groups from API
   */
  getDepartments(limit:number = -1){

    return this._http.get(this.config.restDepartments + '?limit=' + limit, this.httpPostOptions)
      .toPromise()
  }
}
