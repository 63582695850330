import {Config} from "../config/config";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {CookieService, CookieOptions} from "angular2-cookie/core";


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  //cache observable
  private cacheOrders$: Observable<any>;
  //http header
  httpPostOptions = {
    withCredentials: true
  };

  constructor(private config: Config, private _http: HttpClient, private cookies:CookieService) {
  }

  /**
   * Get all Orders
   */
  getOrders(removeCache = false) {
    //if cached, get it
    if (!this.cacheOrders$ || removeCache === true) {
      this.cacheOrders$ = this.getOrdersHelper().pipe(
        shareReplay(1)
      );
    }
    return this.cacheOrders$;
  }

  getOrderByParams(params: object) {
    let body = new HttpParams();
    for (let paramsKey in params) {
      if (params.hasOwnProperty(paramsKey)) {
        body = body.append(paramsKey, params[paramsKey].toString())
      }
    }
    return this._http.get(
      this.config.restOrders,
      {
        params: body,
        withCredentials: true
      },
    ).toPromise();
  }

  /**
   * HTTP request
   */
  getOrdersHelper() {
    return this._http.get(
      this.config.restOrders,
      this.httpPostOptions
    ).pipe(
      map(res => res)
    )
  }

  /**
   * Update order by order id
   * @param form
   * @param orderId
   */
  updateOrder(form, orderId, orderState) {
    //set order status 1
    form['order_state'] = orderState;
    if (orderId) {
      return this._http.put(
        this.config.restOrders + '/' + orderId,
        form,
        this.httpPostOptions
      )
    }
  }

  /**
   * Create temporary order
   * @param form
   * @param callback
   */
  createTempOrder(form, callback) {
    form['order_state']= 2;

    return this._http.post(
      this.config.restOrders,
     form,
      this.httpPostOptions
    ).toPromise().then((res) => {
      if (typeof callback === 'function') {
        callback(res);
      }
    })
  }

  /**
   * Add colli
   * @param colliData
   */
  addColli(colliData) {
    if (!colliData) {
      // create empty promise
      return;
    }
    this.removeKeysWithPrefix(colliData);
    return this._http.post(
      this.config.restOrderCollis,
      colliData,
      this.httpPostOptions
    ).toPromise();

  }

  /**
   * update collis
   * @param colliData
   */
  updateColli(colliData) {
    if (!colliData) {
      // create empty promise
      return;
    }
    this.removeKeysWithPrefix(colliData);
    return this._http.put(
      this.config.restOrderCollis,
      colliData,
      this.httpPostOptions
    ).toPromise();

  }

  /**
   * Remove colli by id
   * @param id
   */
  removeColli(id){
    return this._http.delete(
      this.config.restOrderCollis  + '/' + id,
      this.httpPostOptions
    ).toPromise();
  }

  /**
   * Get colli
   * @param colliId
   * @param callback
   */
  getColli(colliId, callback) {
    if (colliId > 0) {
      //console.log(this.config.restOrderCollis + '/' + colliId)
      this._http.get(this.config.restOrderCollis + '/' + colliId, this.httpPostOptions)
        .toPromise().then((res) => {
        if (typeof callback === 'function') {
          callback(res);
        }
      })
    } else {
      if (typeof callback === 'function') {
        callback([{}]);
      }
    }

  }

  /**
   * Get order by id
   * @param orderId
   */
  getOrderById(orderId) {
    return this._http.get(
      this.config.restOrders + '/' + orderId,
      this.httpPostOptions
    ).toPromise();

  }

  /**
   * Get collis by order id
   * @param orderId
   */
  getCollisByOrderId(orderId) {

    let urlParams: string = 'filter=[{"property":"order_id","value":' + orderId + '}]';


    return this._http.get(
      this.config.restOrderCollis + '?' + urlParams,
      this.httpPostOptions
    ).toPromise();

  }

  /**
   * Delete order by id
   * @param orderId
   */
  deleteOrderById(orderId) {
    return this._http.delete(
      this.config.restOrders + '/' + orderId,
      this.httpPostOptions
    ).toPromise();
  }

  /**
   * Send order to car
   * @param orderId
   */
  sendOrderToCar(orderId){
    let body = new HttpParams();
    body = body.append('order_id',  orderId)
    return this._http.post(
      this.config.tpSendOrderToCar,
      body,
      {
        withCredentials: true
      }

    ).toPromise();
  }

  removeOrderFromCar(orderId){
    let body = new HttpParams();
    body = body.append('order_id',  orderId)
    return this._http.post(
      this.config.tpRemoveOrderFromCar,
      body,
      {
        withCredentials: true
      }

    ).toPromise();
  }

  removeKeysWithPrefix(obj) {
    const prefixesColliRemove = [
      'net_',
      'gross_',
      'length_mm',
      'width_mm',
      'height_mm',
      'vol_ml',
      'vol_mm3',
      'ldm_mm',
      'label_count_dcm',
      'unit_price_dcm',
      'fp_weight_gram',
      'pll_dcm',
      'bund_pll_dcm',
      'swap_pallets_dcm',
      'customs_value_dcm',
      'temp_mdc',
      'count_dcm'
    ];
    Object.keys(obj).forEach(key => {
      prefixesColliRemove.forEach(prefix => {
        if (key.startsWith(prefix)) {
          delete obj[key];
        }
      });
    });
  }
}
