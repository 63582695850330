import {AfterViewInit, Component, OnInit} from '@angular/core';
import Tabulator from 'tabulator-tables';
import * as moment2 from "moment";
import * as moment from "moment";
import {OrdersService} from "../../../services/orders.service";
import {Config} from '../../../config/config';
import {Router} from "@angular/router";
import {NgbCalendar, NgbDateAdapter, NgbDateNativeAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from "angular2-cookie/core";
import {LanguagesService} from "../../../services/languages.service";
import {DataService} from "../../../services/data.service";
import {OrderFilter} from "./order-filter";

moment.locale('dk');

@Component({
  selector: 'app-orders-tab',
  templateUrl: './orders-tab.component.html',
  styleUrls: ['./orders-tab.component.css'],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  }]

})

export class OrdersTabComponent implements OnInit, AfterViewInit {

  public language;
  private languagesTabel ={};

  private appData:any = {};
  private  accountSetting;

  backdrop: boolean = false;
  modelDatePickerPickup: NgbDateStruct;
  modelDatePickerDelivery: NgbDateStruct;

  tableOrder: Tabulator;
  paginationPageNummber = 1;
  //date picker
  filterPickupDate: string;
  filterDeliveryDate: string;

  searchTerm: string;

  urlParams: any;

  showDatepickerPickup: string = 'none';
  showDatepickerDelivery: string = 'none';

  selectCheckBoxValue: any;
  //element to hold order table
  tab = document.createElement('div');
  calendarDateStruct;

  constructor(private orderService: OrdersService,
              private config: Config,
              private router: Router,
              private calendar: NgbCalendar,
              private cookies: CookieService,
              private languages: LanguagesService,
              private dataService: DataService,
              private orderFilter: OrderFilter
  )
  {
    this.appData =  this.dataService.getItems();
    if (Object.keys(this.appData).length === 0) {
      this.router.navigate(['/loader'])
    }


  }

  //lifecycle
  ngAfterViewInit(): void {
    let me = this;
    me.appData;

    this.getOrderColumns(function (model) {
      me.setOrdersTabel(model)
    })
    //console.log(this.appData)
  }

  /**
   * On initialize
   */
  ngOnInit() {

    this.appData =  this.dataService.getItems();
    //set date to cookie if not exists
    if (this.cookies.get('tablet_searchFromDate') != undefined) {
      this.filterPickupDate = moment(this.cookies.get('tablet_searchFromDate')).format('DD-MM-YYYY');
    } else {
      //set default
      this.filterPickupDate = moment().subtract(this.config.defaultFilter['startDateSubtractDays'], "days").format('DD-MM-YYYY');
    }

    if (this.cookies.get('tablet_searchToDate') != undefined) {
      this.filterDeliveryDate = moment(this.cookies.get('tablet_searchToDate')).format('DD-MM-YYYY');
    } else {
      //set default
      this.filterDeliveryDate = moment().add(this.config.defaultFilter['endDateAdditionDays'], "days").format('DD-MM-YYYY');
    }
  }

  /**
   * Set table
   * @param model
   */
  async setOrdersTabel(model) {
    let me = this;
    let filterOrderDefaultStart: string;
    let filterOrderDefaultEnd: string;
    let filterOrderDefaultLimit: string;
    let filterByDepartment:string;
   

    if (this.cookies.get('tablet_searchFromDate') === undefined) {
      filterOrderDefaultStart = moment().subtract(this.config.defaultFilter['startDateSubtractDays'], 'days').format();
      this.cookies.put('tablet_searchFromDate', moment(filterOrderDefaultStart).format())
    } else {
      filterOrderDefaultStart = this.cookies.get('tablet_searchFromDate');
    }
    if (this.cookies.get('tablet_searchToDate') === undefined) {
      filterOrderDefaultEnd = moment().add(this.config.defaultFilter['endDateAdditionDays'], 'days').format();
      this.cookies.put('tablet_searchToDate', moment(filterOrderDefaultEnd).format())
    } else {
      filterOrderDefaultEnd = this.cookies.get('tablet_searchToDate');
    }
    if (this.cookies.get('tablet_searchLimit') === undefined) {
      filterOrderDefaultLimit = this.config.defaultFilter['limit'];
      this.cookies.put('tablet_searchLimit', filterOrderDefaultLimit)
    } else {
      filterOrderDefaultLimit = this.cookies.get('tablet_searchLimit');
    }
    this.orderFilter.clear();
    this.orderFilter.set("created_at",">= " + filterOrderDefaultStart);
    this.orderFilter.set("created_at","<= " + filterOrderDefaultEnd);
    if(this.appData.user.filter_tablet_order_grid_by_user === true){
      this.orderFilter.set("created_by",this.appData.user.name);
    }
    if(this.appData.user.def_department_id != null){
      this.orderFilter.set("department_id",this.appData.user.def_department_id);
    }
    let filter = this.orderFilter.get();

    //get model
    // let modelColumns = ObjModelsOrdersOrders.getModelOrders();
    //todo handle url / api / filter
    this.urlParams = {
      'selectBase': this.orderFilter.selectBase,
      'selects': this.orderFilter.selects,
      'page': 1,
      'limit': filterOrderDefaultLimit,
      'start': 0,
      'filter': JSON.stringify(filter)
    };
    await this.getLanguage();
    this.tableOrder = await new Tabulator(this.tab, {
      layout: "fitDataFill",
      langs: this.languagesTabel,
      height: 500,
      autoResize: true,
      columns: await model,
      placeholder: '...',
      resizableColumns: false,
      movableColumns: false,
      selectable: false,
      persistenceMode: 'local',
      persistentLayout: false,
      ajaxConfig: {
        method: "GET",
        headers: {
          'Accept': 'application/json , text/plain, */*',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include'
      },
      ajaxURL: me.config.restOrders,
      ajaxParams: this.urlParams,
      ajaxRequesting: function (url, params) {
      },
      ajaxResponse: function (url, params, response) {
        let data: any = [];
        let responseData = response.data;

        Object.keys(responseData).forEach((item, index) => {
          //add column pickup_time /delivery_time
          if (moment2(responseData[item]['pickup_date']).isValid()) {
            responseData[item]['pickup_time'] = moment2(responseData[item]['pickup_date']).format('HH:mm');
            responseData[item]['pickup_date'] = moment2(responseData[item]['pickup_date']).format('DD-MM-YYYY').toString();
          } else {
            responseData[item]['pickup_time'] = '00:00';
            responseData[item]['pickup_date'] = '01-01-1971'
          }
          if (moment2(responseData[item]['delivery_date']).isValid()) {
            responseData[item]['delivery_time'] = moment2(responseData[item]['delivery_date']).format('HH:mm');
            responseData[item]['delivery_date'] = moment2(responseData[item]['delivery_date']).format('DD-MM-YYYY').toString();
          } else {
            responseData[item]['delivery_time'] = '00:00';
            responseData[item]['delivery_date'] = '01-01-1971'
          }
          if (responseData[item]['department_id'] != null) {
           let listDepartment = me.appData.department.data;
           let departmentId = responseData[item]['department_id'];
           let currentDepartment = listDepartment.filter(item => item.id === departmentId);
           responseData[item]['department_id'] = currentDepartment[0]['name']; 
          }
           
          data.push(responseData[item])
        });
        return data; //return the tableData property of a response json object
      },
      pagination: "local",
      paginationSize: 50,
      paginationSizeSelector:[5, 10, 50, 100, 500],
      pageLoaded: function (pageno) {
        me.paginationPageNummber = pageno;
      },
      tableBuilt: function () {

      },
      dataLoaded: function () {

      }

    });
    this.tableOrder.setLocale(this.config.defaultLanguage);

    this.paginationPageNummber = this.tableOrder.getPage();

    this.tableOrder.redraw();

    document.getElementById('order-table').appendChild(this.tab);
    /**
     * Eventlister body
     */
    document.body.addEventListener('click', function (evt) {
      let checkBoxes = document.querySelectorAll("input[name^='checkbox-select-row[']");
      me.rowsSelect(checkBoxes)
    }, false);


    let selectCheckBoxes = document.querySelectorAll("input[name^='checkbox-select-row[']");
    selectCheckBoxes.forEach(function (item, index) {
      //me.selectCheckBoxValue[] = item;
      item.addEventListener('click', function () {
      });

    })

  }

  /**
   * get table model for order
   * @param callback
   */
  async getOrderColumns(callback) {
    let me = this;
    let getVisibility= null;
    //set default settings
    let defaultSettings = [
      {name: 'account_id', isVisible: false},
      {name: 'order_number', isVisible: true},
      {name: 'Customer', isVisible: true},
      {name: 'id', isVisible: false},
      {name: 'pickup_date', isVisible: true},
      {name: 'pickup_time', isVisible: true},
      {name: 'delivery_date', isVisible: true},
      {name: 'delivery_time', isVisible: true},
      {name: 'to_name', isVisible: true},
      {name: 'from_name', isVisible: true},
      {name: 'to_name', isVisible: true},
      {name: 'reference', isVisible: true},
      {name: 'autofleet_vehicle', isVisible: true},
      {name: 'to_location', isVisible: true},
      {name: 'debitor',  isVisible: false},
      {name: 'invoice_debitor', isVisible: false},
      {name: 'contract_number', isVisible: false},
      {name: 'Status', isVisible: false},
      {name: 'extra_field1', isVisible: false},
      {name: 'extra_field2', isVisible: false},
      {name: 'extra_field3', isVisible: false},
      {name: 'extra_field4', isVisible: false},
      {name: 'extra_field5', isVisible: false},
      {name: 'extra_field6', isVisible: false},
      {name: 'extra_field7', isVisible: false},
      {name: 'extra_field8', isVisible: false},
      {name: 'extra_field9', isVisible: false},
      {name: 'extra_field10', isVisible: false},
      {name: 'total_collis_count', isVisible: false},
      {name: 'total_collis_weight',  isVisible: false},
      {name: 'total_collis_volume',  isVisible: false},
      {name: 'total_collis_ldm', isVisible: false},
      {name: 'total_collis_pll',  isVisible: false},
      {name: 'total_collis_bund_pll', isVisible: false},
      {name: 'total_collis_swap_pallets', isVisible: false},
      {name: 'created_at', isVisible: false},
      {name: 'created_by', isVisible: false},
      {name: 'to_address', isVisible: false},
      {name: 'to_city', isVisible: false},
      {name: 'to_zipcode', isVisible: false},
      {name: 'to_country', isVisible: false},
      {name: 'from_address', isVisible: false},
      {name: 'from_city', isVisible: false},
      {name: 'from_zipcode', isVisible: false},
      {name: 'from_country',  isVisible: false},
      {name: 'Department',  isVisible: false}
    ];


    try{
      //get settings from database else use default settings
      if (typeof this.appData['account']['tablet_ordergrid_settings'] === 'string') {
        this.accountSetting = await JSON.parse(this.appData['account']['tablet_ordergrid_settings']);
      } else {
        this.accountSetting =  defaultSettings;
      }
      //function to set visibility
      getVisibility = (field) => {
        let visible = false;
        this.accountSetting.some((item, index) => {

          if ( item.name === field) {
            visible = item.isVisible;
            return true;
          }
        });
        return visible;
      };

    } catch (e) {
      console.error(e);
    }


    //table columns
    let orderModel = [
      {
        formatter:"rowSelection",
        visible: false,
        titleFormatter:"rowSelection",
        align:"center",
        headerSort:false,
        cellClick:function(e, cell){
          cell.getRow().toggleSelect();
        },
        width: 30
      },
      {
        width: 40,
        visible: true,
        headerSort:false,
        formatter: function (cell) {
          let row = cell.getRow();
          return '<i style="font-size: 1.5em; margin: 3px 0 0 3px;  cursor: pointer;" class="fa fa-print"></i>';
        },
        cellClick: function (e, cell) {
          let data = cell.getData();

          let labelLanguages = me.appData['account'].label_template_languages;
          if(labelLanguages.length > 0){
            let arrLang = labelLanguages.split(',');
            //console.log(1,Tabulator.prototype.defaultOptions);
          }
          window.open(me.config.mainUrl + '/'+ data.account_id.toString()+'/waybill/' + data.id.toString(), '_blank');
        }
      },{
        width: 40,
        visible: true,
        headerSort:false,
        formatter: function (cell) {
          let row = cell.getRow();
          return '<i style="font-size: 1.5em; margin: 3px 0 0 3px;  cursor: pointer;" class="fa fa-tag"></i>';
        },
        cellClick: function (e, cell) {
          let data = cell.getData();
          window.open(me.config.mainUrl + '/'+ data.account_id.toString()+'/label/' + data.id.toString(), '_blank');

        }
      },
      {
        width: 40,
        visible: true,
        headerSort:false,
        formatter: function (cell) {
          let row = cell.getRow();

          let value = '<i style="font-size: 1.5em; margin: 3px 0 0 3px;  cursor: pointer;" class="fa fa-edit"></i>';
          return value;
        },
        cellClick: function (e, cell) {
          let data = cell.getData();
          me.updateTransport(data.order_type, data.id);
        }
      },
      {
        width: 40,
        visible: true,
        headerSort:false,
        formatter: function (cell) {
          let row = cell.getRow();
          return '<i style="font-size: 1.5em; margin: 3px 0 0 3px; cursor: pointer; " class="fa fa-trash text-danger"></i>';
        },
        cellClick: function (e, cell) {
          let data = cell.getData();
          let confirmDelete = confirm('Delete');
          if (confirmDelete === true) {
            cell.setValue('<i style="font-size: 1.5em; margin: 3px 0 0 3px; cursor: pointer; " class="fa fa-spinner fa-spin text-danger"></i>');

            me.deleteOrder(data.id,function () {
              cell.getTable().setData();
            });
          }
        }
      },
      {
        title: "",
        field: "at_car",
        width: 50,
        headerSort:false,
        headerFilter:"tickCross",
        headerFilterParams:{"tristate":true},
        headerFilterEmptyCheck:function(value){return value === null},
        visible: true,
        formatter: function (cell, formatterParams, onRendered) {

          var returnValue;
          if(cell.getValue() === true){
             returnValue = '<div style="width: 100%; text-align: center"><img style="height: 25px" alt="" src="/assets/images/mobile_go_checked.png"></div>'
          } else {
            returnValue = '<div  style="width: 100%; text-align: center"><img style="height: 25px" alt="" src="/assets/images/mobile_go.png"></div>'
          }
          return returnValue;
        },
        cellClick(e,cell){
          me.sendOrderToCar(cell);
        }
      },
      {
        title: "Account",
        field: "account_id",
        visible: await getVisibility('account_id')
      },
      {
        title: "Waybill",
        field: "id",
        visible: await getVisibility('id'),
        resizable: false,
        width: 90,
        headerVisible: true,
        sorter: "number",
        headerFilter: "input",
        headerFilterPlaceholder: " ",

        formatter: function (cell) {
          let value = cell.getValue();

          return '<div style="color: #1d68cd; cursor: pointer">' + value + '</div>'
        },
        cellClick: function (e, cell) {
          let data = cell.getData();
          me.updateTransport(data.order_type, data.id);
        }
      },
      {
        title: "Order number",
        field: "order_number",
        headerVisible: true,
        visible: await getVisibility('order_number'),
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        sorter: "number",
        width: 90,
        headerSort: true,
        formatter: function (cell) {
          let value = cell.getValue();

          return '<div style="color: #1d68cd; cursor: pointer">' + value + '</div>'
        },
        cellClick: function (e, cell) {
          let data = cell.getData();
          me.updateTransport(data.order_type, data.id);
        }
      },
      {
        title: "Customer.name",
        field: "Customer.name",
        // formatter: function (cell) {
        //   //cell.getElement().style.backgroundColor = cell.getValue().color;
        //   return cell.getValue().name;
        // },
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('Customer'),
      },
      {
        title: "Pickup",
        field: "pickup_date",

        resizable: false,
        visible: getVisibility('pickup_date'),
        width: 110,
        align: "left",
        sorter: "date",
        sorterParams: {
          format: "DD-MM-YYYY",
          alignEmptyValues: "top",
        },
        headerVisible: true,
        headerFilter: "input",
        headerFilterFunc: OrdersTabComponent.pickupDateFilter,
        headerFilterPlaceholder: " ",
        headerFilterParams: {
          format: "DD-MM-YYYY"
        }
      },
      {
        title: " ",
        field: "pickup_time",
        resizable: false,
        formatter: function (cell) {
          let value = cell.getValue();
          if (value != null) {
            return value;
          }
        },
        visible: getVisibility('pickup_time'),
        width: 60,
        align: "left",
        headerFilter: false,
        headerSort: false,
        cssClass: 'no-border'
      },
      {
        title: "Delivery",
        field: "delivery_date",
        resizable: false,
        visible: getVisibility('delivery_date'),
        width: 110,
        align: "left",
        sorter: "date",
        sorterParams: {
          format: "DD-MM-YYYY",
          alignEmptyValues: "top",
        },
        headerFilter: "input",
        headerFilterFunc: OrdersTabComponent.deliveryDateFilter,
        headerFilterPlaceholder: " ",
        headerFilterParams: {
          format: "DD-MM-YYYY"
        }
      },
      {
        title: " ",
        field: "delivery_time",
        visible: getVisibility('delivery_time'),
        resizable: false,
        formatter: function (cell) {
          let value = cell.getValue();
          if (value != null) {
            return value;
          }
        },
        width: 60,
        align: "left",
        headerSort: false,
      },
      {
        title: "Pickup name",
        field: "from_name",
        visible: getVisibility('from_name'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Pickup address",
        field: "from_address1",
        visible: getVisibility('from_address1'),
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Pickup city",
        field: "from_city",
        visible: getVisibility('from_city'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Pickup zipcode",
        field: "from_zipcode",
        visible: getVisibility('from_zipcode'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Pickup country",
        field: "from_country",
        visible: getVisibility('from_country'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Delivery name",
        field: "to_name",
        visible: getVisibility('to_name'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Delivery address",
        field: "to_address1",
        visible: getVisibility('to_address1'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Delivery city",
        field: "to_city",
        visible: getVisibility('to_city'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Delivery zipcode",
        field: "to_zipcode",
        visible: getVisibility('to_zipcode'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Delivery country",
        field: "to_country",
        visible: getVisibility('to_country'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: "Reference",
        field: "reference",
        visible: getVisibility('reference'),
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,

      },
      {
        title: 'Vehicle',
        field: 'autofleet_vehicle',
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('autofleet_vehicle')
      },
      {
        title: "Contract number",
        field: "contract_number",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('contract_number')
      },
      {
        title: "Status",
        field: "Status",
        formatter: function (cell) {
          //cell.getElement().style.backgroundColor = cell.getValue().color;
          cell.getElement().style.borderLeft = "solid 5px " + cell.getValue().color;
          return cell.getValue().status_text;
        },
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('Status')
      },
      {
        title: "Debitor",
        field: "Customer.debitor_no",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('debitor')
      },
      {
        title: "Invoice Debitor",
        field: "InvoiceCustomer.debitor_no",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('invoice_debitor')
      }, {
        title: "Extra field 1",
        field: "extra_field1",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field1')
      },
      {
        title: "Extra field 2",
        field: "extra_field2",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field2')
      },
      {
        title: "Extra field 3",
        field: "extra_field3",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field3')
      },
      {
        title: "Extra field 4",
        field: "extra_field4",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field4')
      },
      {
        title: "Extra field 5",
        field: "extra_field5",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field5')
      },
      {
        title: "Extra field 6",
        field: "extra_field6",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field6')
      },
      {
        title: "Extra field 7",
        field: "extra_field7",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field7')
      },
      {
        title: "Extra field 8",
        field: "extra_field8",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field8')
      },
      {
        title: "Extra field 9",
        field: "extra_field9",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field9')
      },
      {
        title: "Extra field 10",
        field: "extra_field10",
        minWidth: 80,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('extra_field10')
      },
      {
        title: "Total collis",
        field: "total_collis_count",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_count')
      },   {
        title: "Total Collis Weight",
        field: "total_collis_weight",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_weight')
      },   {
        title: "Total collis volume",
        field: "total_collis_volume",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_volume')
      },   {
        title: "Total collis ldm",
        field: "total_collis_ldm",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_ldm')
      },   {
        title: "Total collis pll",
        field: "total_collis_pll",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_pll')
      },   {
        title: "Total collis pll",
        field: "total_collis_bund_pll",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_bund_pll')
      },   {
        title: "Total collis swap pallets",
        field: "total_collis_swap_pallets",
        minWidth: 50,
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('total_collis_swap_pallets')
      },
      {
        title: "Created",
        field: "created_at",
        resizable: false,
        visible: getVisibility('created_at'),
        width: 110,
        align: "left",
        sorter: "date",
        sorterParams: {
          format: "DD-MM-YYYY",
          alignEmptyValues: "top",
        },
        headerFilter: "input",
        headerFilterFunc: OrdersTabComponent.pickupDateFilter,
        headerFilterPlaceholder: " ",
        headerFilterParams: {
          format: "DD-MM-YYYY"
        },
        formatter: function (cell) {
          let value = cell.getValue();

          if (value != null) {
            value = moment(value).format('DD-MM-YYYY');
            return value;
          }
        },
      },
      {
        title: "Created by",
        field: "created_by",
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('created_by')
      }
      ,
      {
        title: "Department",
        field: "department_id",
        headerFilter: "input",
        headerFilterPlaceholder: " ",
        headerSort: true,
        resizable: false,
        visible: getVisibility('Department')
      }
    ];

    //remove invisible columns
    let newOrderModel=[];
    orderModel.forEach((item)=>{
      if('visible' in item && item.visible === true){
        delete item.visible;
        newOrderModel.push(item)
      }
    });

    if (typeof callback === 'function') {
      callback(newOrderModel)
    }

  }

  /**
   * Select with checkboxes
   * @param checkBoxes
   */
  rowsSelect(checkBoxes) {
    let rows = this.tableOrder.getRows();
    checkBoxes.forEach(function (item) {

    })
  }

  /**
   * toggle calendar popup
   * @param event
   * @param element
   */
  toggleDatePickerFilterPickup(event, element) {
    let newDateArray = this.filterPickupDate.split('-', 3);
    let dateObj = {
      year: parseInt(newDateArray[2]),
      month: parseInt(newDateArray[1]),
      day: parseInt(newDateArray[0])
    };
    element.navigateTo(dateObj);

    this.showDatepickerPickup = this.showDatepickerPickup === 'none' ? 'inline' : 'none';
  }

  onBlurHideDatePickerPickup() {
    this.showDatepickerPickup = 'none';
  }

  onBlurHideDatePickerDelivery() {
    this.showDatepickerDelivery = 'none';
  }

  /**
   * toggle calendar popup
   * @param event
   * @param element
   */
  toggleDatePickerFilterDelivery(event, element) {
    let newDateArray = this.filterDeliveryDate.split('-', 3);
    let dateObj = {
      year: parseInt(newDateArray[2]),
      month: parseInt(newDateArray[1]),
      day: parseInt(newDateArray[0])
    };
    element.navigateTo(dateObj);
    this.showDatepickerDelivery = this.showDatepickerDelivery === 'none' ? 'inline' : 'none';
  }

  /**
   * Datepicker function
   * @param date
   */
  onDatePickerChangeFilterDelivery(date) {
    //set cookie
    this.cookies.put('tablet_searchToDate', moment(date).format());
    this.filterDeliveryDate = moment(date).format('DD-MM-YYYY').toString();
    this.showDatepickerDelivery = this.showDatepickerDelivery === 'none' ? 'inline' : 'none';
  }

  /**
   * Datepicker function
   * @param date
   */
  onDatePickerChangeFilterPickup(date) {
    //set cookie
    this.cookies.put('tablet_searchFromDate', moment(date).format());
    this.filterPickupDate = moment(date).format('DD-MM-YYYY').toString();
    this.showDatepickerPickup = this.showDatepickerPickup === 'none' ? 'inline' : 'none';
  }

  /**
   * Pickup date filter methode
   * @param headerValue
   * @param rowValue
   * @param rowData
   * @param filterParams
   */
  protected static pickupDateFilter(headerValue, rowValue, rowData, filterParams): boolean {

    let deliveryDateDisplayFormatted = moment2(rowData.pickup_date).format('DD-MM-YYYY');
    headerValue = headerValue.replace(/\*/g, "");
    headerValue = headerValue.replace(/\?/g, '');
    let returnValue = deliveryDateDisplayFormatted.match(headerValue);
    return !!returnValue; //must return a boolean, true if it passes the filter.
  }

  /**
   * Delivery date filter methode
   * @param headerValue
   * @param rowValue
   * @param rowData
   * @param filterParams
   */
  protected static deliveryDateFilter(headerValue, rowValue, rowData, filterParams) {

    let deliveryDateDisplayFormatted = moment2(rowData.delivery_date).format('DD-MM-YYYY');
    headerValue = headerValue.replace(/\*/g, "");
    headerValue = headerValue.replace(/\?/g, '');
    let returnValue = deliveryDateDisplayFormatted.match(headerValue);
    return !!returnValue; //must return a boolean, true if it passes the filter.
  }

  /**
   * Navigate to new/edit order form
   * @param orderType string describes order type [transport|pickup|delivery|...]
   */
  newTransport(orderType) {
    this.router.navigate(['/edit', {orderType: orderType, action: "newOrder", order_id: 0}])
  }

  /**
   * Update transport order
   * @param orderType
   * @param orderid
   */
  updateTransport(orderType: string, orderid: number = 0) {
    this.router.navigate(['/edit', {orderType: orderType, action: "updateOrder", order_id: orderid}])
  }

  /**
   * Delete by order id
   * @param orderId
   * @param callback
   */
  deleteOrder(orderId, callback) {
    let me = this;
    if (orderId > 0) {
      me.backdrop = true;
      this.orderService.deleteOrderById(orderId)
        .then(function (res) {
          me.backdrop = false;
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(() => {
          console.error('Error delete order');
          let error = 'Something goes wrong while deleting the order';
          me.backdrop = false;
          callback(error);
        })
    }
  }

  /**
   * Search order
   */
  search() {

    this.cookies.remove('tablet_searchFromDate');
    this.cookies.remove('tablet_searchToDate');

    let params;
    let arrPickupDate = this.filterPickupDate.split('-');
    let arrDeliveryDate = this.filterDeliveryDate.split('-');

    this.orderFilter.clear();
    this.orderFilter.set('created_at','>= ' + moment(new Date(arrPickupDate[2] + '-' + arrPickupDate[1] + '-' + arrPickupDate[0])).format('YYYY-MM-DD 00:00:00'))
    this.orderFilter.set('created_at','<= ' +moment(new Date(arrDeliveryDate[2] + '-' + arrDeliveryDate[1] + '-' + arrDeliveryDate[0])).format('YYYY-MM-DD 23:59:59') );
    if(this.appData.user.filter_tablet_order_grid_by_user === true){
      this.orderFilter.set("created_by",this.appData.user.name);
    }

    this.cookies.put('tablet_searchFromDate', moment(new Date(arrPickupDate[2] + '-' + arrPickupDate[1] + '-' + arrPickupDate[0])).format());
    this.cookies.put('tablet_searchToDate', moment(new Date(arrDeliveryDate[2] + '-' + arrDeliveryDate[1] + '-' + arrDeliveryDate[0] + ' 23:59:59')).format());


    this.urlParams = {
      'selectBase': this.orderFilter.selectBase,
      'selects': this.orderFilter.selects,
      'page': 1,
      'limit': this.config.defaultFilter['limit'],
      'start': 0,
      'filter': JSON.stringify(this.orderFilter.get())
    };
    this.tableOrder.setData(this.config.restOrders, this.urlParams);

  }

  /**
   * Set today pickup date
   */
  setTodayToPickupDate() {
    this.filterPickupDate = moment().format('DD-MM-YYYY');
    this.showDatepickerPickup = 'none'
  }

  /**
   * Set today delivery date
   */
  setTodayToDeliveryDate() {
    this.filterDeliveryDate = moment().format('DD-MM-YYYY');
    this.showDatepickerDelivery= 'none'
  }

  translate(text){
    return text;
  }
  async getLanguage(){
    let me = this;
    await this.languages.getLanguageCacheComponentGridsOrder()
      .then(function (res) {
        me.language = res['languages'][me.config.defaultLanguage];
        //extra field labels
        if(me.appData.account.extra_order_fields_new){
          let extraFieldLabels=JSON.parse(me.appData.account.extra_order_fields_new);
          if( Object.keys(extraFieldLabels).length > 0){
            Object.keys(extraFieldLabels).forEach((key) => {
              me.language.columns[extraFieldLabels[key]['name']]= extraFieldLabels[key]['label']
            });
          }
        }
        me.languagesTabel = res['languages'];
      });
  }

  sendOrderToCar(cell){
    let data = cell.getData();
    let row = cell.getRow();
    if(data.at_car === false){

      this.orderService.sendOrderToCar(data.id)
        .then((promise)=>{
              this.tableOrder.updateData([{id:data.id,at_car:true}])
        })
    } else if(data.at_car === true){

      this.orderService.removeOrderFromCar(data.id)
        .then((promise)=>{
              this.tableOrder.updateData([{id:data.id,at_car:false}])
        })
    }


  }

}
