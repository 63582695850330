import {Component, OnInit} from '@angular/core';
import {ProfilesService} from "../../services/profiles.service";
import {WaresService} from "../../services/wares.service";
import {CustomerService} from "../../services/customer.service";
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {CountriesService} from "../../services/countries.service";
import {VehicleService} from "../../services/vehicle.service";
import {UnitsconversionsService} from "../../services/unitsconversions.service";
import {AuthService} from "../../services/auth.service"
import {OrdergroupsService} from "../../services/ordergroups.service";
import { DepartmentsService } from 'src/app/services/departments.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loaderProgress: number = 0;
  i: number = 0;


  constructor(
    private profileService: ProfilesService,
    private waresService: WaresService,
    private customerService: CustomerService,
    private countriesService: CountriesService,
    private vehicleService: VehicleService,
    private ordergroupsService: OrdergroupsService,
    private authService: AuthService,
    private unitConversions: UnitsconversionsService,
    private deparments: DepartmentsService,
    public appData: DataService,
    private router: Router

  ) {
  }

  async  ngOnInit() {
    let progressPerService = 11.11112;

    await this.authService.getAccountSession()
      .then((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('accountsession', res['data']);
        this.appData.add('user', res['data']['user']);
        this.loadCheck('authService');
         this.authService.accounts(res['data']['user'].account_id)
          .then((res) => {
            this.loaderProgress += progressPerService;
            this.appData.add('account', res['data']);
            this.loadCheck('authService');
          }).catch((e)=>{
            for (let eKey in e) {
              if(e.hasOwnProperty(eKey)){
                console.error(e[eKey]);
              }
              this.router.navigate(['/'])
            }
          });

      }).catch((e)=>{
        for (let eKey in e) {
          if(e.hasOwnProperty(eKey)){
            console.error(e[eKey]);
          }
          this.router.navigate(['/'])
        }
      });

     
      await this.deparments.getDepartments()
      .then((res)=>{
        this.loaderProgress += progressPerService;
        this.appData.add('department',res);
        this.loadCheck('department');
      })

    await this.profileService.getProfiles()
      .then((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('profiles', res);
        this.loadCheck('profileService');
      }).catch((e)=>{
        for (let eKey in e) {
          if(e.hasOwnProperty(eKey)){
            console.error(e[eKey]);
          }
          this.router.navigate(['/'])
        }
      });
    await this.waresService.getWares()
      .subscribe((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('wares', res);
        this.loadCheck('waresService');
      });
    await this.customerService.getCustomers()
      .subscribe((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('customers', res);
        this.loadCheck('vehicleService');
      });
    await this.countriesService.getCountries()
      .then((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('countries', res);
        this.loadCheck('customerService');
      }).catch((e)=>{
        for (let eKey in e) {
          if(e.hasOwnProperty(eKey)){
            console.error(e[eKey]);
          }
          this.router.navigate(['/'])
        }
      });

    await this.vehicleService.getVehicles()
      .then((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('vehicles', res);
        this.loadCheck('vehicleService');
      }).catch((e)=>{
        for (let eKey in e) {
          if(e.hasOwnProperty(eKey)){
            console.error(e[eKey]);
          }
          this.router.navigate(['/'])
        }
      });

    await this.ordergroupsService.getOrdergroups()
      .then((res) => {
        this.loaderProgress += progressPerService;
        this.appData.add('ordergroups', res);
        this.loadCheck('ordergroupsService');
      }).catch((e)=>{
        for (let eKey in e) {
          if(e.hasOwnProperty(eKey)){
            console.error(e[eKey]);
          }
          this.router.navigate(['/'])
        }
      });

    await this.unitConversions.getUnitConversions()
      .then((res)=>{
        this.loaderProgress += progressPerService;
        this.appData.add('unitConversions',res);
        this.loadCheck('unitConversions');
      })
   
  }

  /**
   * Check number of progress, if 100, go to order component
   */
  async loadCheck(service) {
    //console.log(service,this.loaderProgress )
    if (this.loaderProgress > 99) {
      // console.log('appData',this.appData )
      this.router.navigate(['/orders-tab'])
    }
  }

}
