import { Injectable } from '@angular/core';
import {Config} from "../config/config";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UnitsconversionsService {
  //http header
  httpPostOptions = {
    withCredentials: true
  };
  constructor(private config: Config,private _http:HttpClient) { }

  getUnitConversions(limit=-1){
    //only not soft deleted vehicle
    return this._http.get(this.config.restUnitConversion + '?limit='+ limit, this.httpPostOptions)
      .toPromise()
  }
}
