import {AfterContentInit, AfterViewInit, Component, OnInit} from '@angular/core';

import {Config} from "../../../config/config";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbCalendar, NgbDateStruct, NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from "../../../app.component";
import {LoaderComponent} from "../../loader/loader.component";

import {LanguagesService} from "../../../services/languages.service";
import {OrdersService} from "../../../services/orders.service";
import {CustomerSpecificationsService} from "../../../services/customer-specifications.service";
import {DataService} from "../../../services/data.service";
import {WaresService} from "../../../services/wares.service";
import {CustomerService} from "../../../services/customer.service";

import {ProfilesService} from "../../../services/profiles.service";
import {CollisModel} from "../../../models/collis.model";
import {ProfileModel} from "../../../models/profile.model";

import {SaveOrder} from "./tools/save-order";
import {SaveColli} from "./tools/save-colli";
import * as moment from "moment";
import Tabulator from 'tabulator-tables';
import {CommoditiesService} from "../../../services/commodities.service";
import {AddressService} from "../../../services/address.service";

import {OrdergroupsService} from "../../../services/ordergroups.service";
import {DepartmentsService} from "../../../services/departments.service";

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css'],
  providers: [
    CollisModel, SaveOrder, SaveColli, LoaderComponent, ProfileModel
  ]
})
export class EditOrderComponent implements OnInit, AfterViewInit, AfterContentInit {
  options = {
    fields: ['address_components', 'geometry'],
  };

  public tableColli: Tabulator;
  //collis table definition
  collisTableDefinition: any = [];
  divColliTable = document.createElement('div');
  //language data for template
  public language: {};
  model: NgbDateStruct;
  value: Date;
  //timepicker
  pickup_time: string = '00:00';
  delivery_time: string = '00:00';
  today: string = moment().format('DD-MM-YYYY');
  //date picker
  from_date;
  to_date;
  //countries
  from_country: string;
  to_country: string;
  //vehicle
  autofleet_vehicle;
  errorMsg = [];
  //order form
  formEditOrder: FormGroup;
  //customer data
  dataCustomer: any;
  //data countries
  dataCountries: any;
  //data vehicles
  dataVehicles: any;
  //translate
  translate: any = {};
  //Query params
  queryParams: any;
  //order type
  orderType: string = '';
  //order data
  orderData: any;
  //order_id
  order_id: number = 0;
  //customer specifications data
  contract_number = "";
  customerSpecificationsData;
  customerSpecificationsPlaceholder: string = '';
  //group
  group = "";
  groupData;
  groupPlaceholder: string = '';
  //departments
  department_id;
  departmentData;
  departmentPlaceholder: string = "";

  internalNotesPlaceholder: string = "";
  //customer select box
  customer_id: string ='';
  customerSelectBoxPlaceholer: string = '';
  //addresses
  public pickupAddress: any;
  public deliveryAddress: any;
  addressDataAll: any;
  addressData: any = {};

  selectBoxCustomerOptions = {
    width: '100%'
  };
  selectBoxCustomerSpecificationsOptions = {
    width: '100%'
  };
  selectBoxGroupOptions = {
    width: '100%'
  };
  selectBoxCountriesOptions = {
    width: '100%'
  };
  appData: any = [];
  //tabel grid
  tableCollis;
  //backdrop tabel collis
  backdropTabelCollis: boolean = false;
  //backdrop edit order
  backdropEditOrder: boolean = false;

  showDatepickerPickup: boolean = false;
  showDatepickerDelivery: boolean = false;


  /**
   * get wares
   */
  jsonElementsKeyValue = [];
  jsonElementsKeyValueOriginal = [];
  jsonElementsKeyValueProfileColli = {};
  //get wares
  wares: [] = [];
  //build data to use in selectbox ware
  keyValuesOriginalWare = [];
  keyValuesWare = [];
  //url querys
  newQueryParams;

  //classes sidebar icons
  sidebarIconHome = 'icon-disabled fa fa-home sidebar-icons';

  profile: ProfileModel = new ProfileModel();


  /**
   * Constructor
   * @param myApp
   * @param customers data from rest API
   * @param formBuilder
   * @param router
   * @param route
   * @param config
   * @param languages
   * @param profiles
   * @param waresService
   * @param orderService
   * @param customerSpecificatonsService
   * @param timePickerConfig
   * @param calendar
   * @param collisModel
   * @param saveOrder
   * @param saveColli
   * @param dataService
   * @param commoditiesService
   * @param profileModel
   * @param addressService
   * @param ordergroupsService
   * @param departmentsService
   */
  constructor(
    private myApp: AppComponent,
    private customers: CustomerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private config: Config,
    private languages: LanguagesService,
    private profiles: ProfilesService,
    private waresService: WaresService,
    private orderService: OrdersService,
    public dataService: DataService,
    private customerSpecificatonsService: CustomerSpecificationsService,
    private timePickerConfig: NgbTimepickerConfig,
    private calendar: NgbCalendar,
    private collisModel: CollisModel,
    private saveOrder: SaveOrder,
    private saveColli: SaveColli,
    private commoditiesService: CommoditiesService,
    private profileModel: ProfileModel,
    private addressService: AddressService,
    private ordergroupsService: OrdergroupsService,
    private departmentsService:DepartmentsService
  ) {

    this.appData = this.dataService.getItems();
    if (Object.keys(this.appData).length === 0) {
      this.router.navigate(['/loader'])
    }
    /***********************************************
     * Date and time inputs on form
     **********************************************/
    //get from_time
    this.pickup_time = '06:00';
    this.delivery_time = '16:00';
    timePickerConfig.seconds = false;
    timePickerConfig.spinners = false;// disable default buttons to timepicker spinner


  }

  public profileColliData: object[] = [
    {code: 'pll', text: 'PLL'}
  ];

  //lifecycle
  ngAfterContentInit(): void {
    //get query parmas
    let queryParams = this.queryParams = this.route.snapshot.params;
    //set order type
    this.orderType = this.queryParams['orderType'];
    this.order_id = this.queryParams['order_id'];

  }

  ngAfterViewInit(): void {
    let me = this;

    this.languages.getLanguageCacheComponentFormsEditOrder(true)
      .subscribe(function (res) {
        me.language = res.languages[me.config.defaultLanguage];
        me.customerSelectBoxPlaceholer = me.language['customers'];
        me.customerSpecificationsPlaceholder = me.language['customer_specification'];
        me.groupPlaceholder = me.language['chooseGroup'];
        me.departmentPlaceholder = me.language['departmentPlaceholder'];
        me.internalNotesPlaceholder = me.language['internalNotesPlaceholder'];
        me.newTableCollis(me.order_id, res.languages);
      });

    //pickup date/time
    if (this.appData.account.use_now_as_def_order_pickup_time === true) {
      //set time on now
      this.pickup_time = moment().format('hh:mm');
      this.formEditOrder.controls.pickup_time.setValue(moment().format('hh:mm'));
      //set day on today
      this.formEditOrder.controls.pickup_date.setValue(moment().format('DD-MM-YYYY'));
    } else if (this.appData.account.def_order_pickup_time) {
      //time from settings
      this.pickup_time = this.appData.account.def_order_pickup_time;
      this.formEditOrder.controls.pickup_time.setValue(this.appData.account.def_order_pickup_time)
    }

    //delivery date/time
    if (this.appData.account.use_now_as_def_order_delivery_time === true) {
      //set time on now
      this.delivery_time = moment().format('hh:mm');
      this.formEditOrder.controls.delivery_time.setValue(moment().format('hh:mm'));
      //set day on today
      this.formEditOrder.controls.delivery_date.setValue(moment().format('DD-MM-YYYY'));
    } else if (this.appData.account.def_order_delivery_time) {
      //time from settings
      this.delivery_time = this.appData.account.def_order_delivery_time;
      this.formEditOrder.controls.delivery_time.setValue(this.appData.account.def_order_delivery_time)
    }

    if(this.appData.account.def_order_delivery_date_enable === true){
      this.formEditOrder.controls.delivery_date.setValue(moment().add(this.appData.account.def_order_delivery_date, "days").format('DD-MM-YYYY'));
    }
    if(this.appData.account.def_order_pickup_date_enable === true){
      this.formEditOrder.controls.pickup_date.setValue(moment().add(this.appData.account.def_order_pickup_date, "days").format('DD-MM-YYYY'));
    }


  }

  ngOnInit() {

    let me = this;
    let newCustomerItems = [];
    if (typeof this.appData['customers'] === 'object') {
      this.appData['customers'].forEach(function (item) {
        if(item.is_blocked === false){
          newCustomerItems.push({
            id: item['id'],
            text: item['name']
          })
        }
      })
    }
    this.dataCustomer = newCustomerItems;

    let newCountriesItems = [];
    if (typeof this.appData['countries'] === 'object') {
      for (let appDatumElementKey in this.appData['countries']['data']) {
        if (this.appData['countries']['data'].hasOwnProperty(appDatumElementKey)) {
          newCountriesItems.push({
            id: appDatumElementKey,
            text: this.appData['countries']['data'][appDatumElementKey]
          })
        }
      }
    }
    //this.dataCountries = newCountriesItems;
    this.dataCountries = newCountriesItems;

    let newVehiclesItems = [];
    if (typeof this.appData['vehicles'] === 'object') {
      let vehicles = this.appData['vehicles']['data'];
      for (let appDataKey in vehicles) {
        if (vehicles.hasOwnProperty(appDataKey)) {
          newVehiclesItems.push({
            id: vehicles[appDataKey]['name'],
            text: vehicles[appDataKey]['name']
          })
        }
      }
    }

    this.dataVehicles = newVehiclesItems;


    let deliveryDate = moment().add(1, "days").format('DD-MM-YYYY');
    let pickupDate = moment().format('DD-MM-YYYY');

    if(this.appData.account.def_order_delivery_date_enable === true){
      deliveryDate = moment().add(this.appData.account.def_order_delivery_date, "days").format('DD-MM-YYYY');
    }
    if(this.appData.account.def_order_pickup_date_enable === true){
      pickupDate = moment().add(this.appData.account.def_order_pickup_date, "days").format('DD-MM-YYYY');
    }


    //get language to template and table
    //initialize form
    this.formEditOrder = this.formBuilder.group({
      order_type: ['transport'],
      customer_id: [null, Validators.required],
      comment: [null],
      reference: [null],
      autofleet_vehicle: [null],
      agreed_cost: [null],
      agreed_time: [null],
      agreed_km: [null],
      from_name: [null, Validators.required],
      search_from_location: [null],
      from_address1: [null, Validators.required],
      from_zipcode: [null, Validators.required],
      from_city: [null, Validators.required],
      from_country: [null, Validators.required],
      to_name: [null, Validators.required],
      search_to_location: [null],
      to_address1: [null, Validators.required],
      to_zipcode: [null, Validators.required],
      to_city: [null, Validators.required],
      to_country: [null, Validators.required],
      pickup_time: [this.pickup_time],
      delivery_time: [this.delivery_time],
      pickup_date: [pickupDate],
      delivery_date: [deliveryDate],
      contract_number: [null],
      group:[this.group],
      department_id:[this.department_id],
      internal_notes: [null]
    });
    //get query parmas
    this.newQueryParams = this.queryParams = this.route.snapshot.params;
    //set order type
    this.orderType = this.queryParams['orderType'];
    //get adresses
    this.getAddresses();

    //set form data
    if (this.newQueryParams.action === 'newOrder') {
      //set order type
      if (this.orderType.length > 0) {
        this.formEditOrder.controls.order_type.setValue(this.orderType)
      }
      if (me.appData.user.order_group != '') {
        me.group = me.appData.user.order_group;
        me.formEditOrder.controls.group.setValue(me.group);
      }
      let orderToUpdate = this.formEditOrder.value;
      //fix date before saving
      let tempPickup = this.formEditOrder.controls.pickup_date.value.split('-');
      let tempDelivery = this.formEditOrder.controls.delivery_date.value.split('-');
      orderToUpdate['pickup_date'] = moment(new Date(tempPickup[2] + '-' + tempPickup[1] + '-' + tempPickup[0] + ' ' + this.formEditOrder.controls.pickup_time.value)).format();
      orderToUpdate['delivery_date'] = moment(new Date(tempDelivery[2] + '-' + tempDelivery[1] + '-' + tempDelivery[0] + ' ' + this.formEditOrder.controls.delivery_time.value)).format();
      this.saveOrder.createTempOrder(orderToUpdate, function (order_id) {
        me.order_id = order_id;
        let orderData = [];
        if (me.order_id > 0) {
          me.orderService.getOrderById(me.order_id)
            .then((res) => {
              orderData = res['data'];
              me.getWares();
              me.newTableCollis(me.order_id);
              // set default department id exists
              if (me.appData.user.def_department_id > 0) {
                me.department_id = me.appData.user.def_department_id;
                me.formEditOrder.controls.department_id.setValue(me.department_id);
              }
            })
        }


      });
    }
    //create order form to update order data
    else if (this.newQueryParams.action === 'updateOrder') {
      me.order_id = this.newQueryParams.order_id;
      //get order data
      let orderData = [];
      if (this.order_id > 0) {

        this.backdropEditOrder = true;
        this.orderService.getOrderById(this.order_id)
          .then((res) => {
            me.orderData = res['data'];
            this.setFormOrder(me.orderData)
          }).finally(() => {
          this.backdropEditOrder = false;
          me.setColumnSelectBoxData(true, (selecBoxData) => {
          })
        })
      }
      this.getWares();
    }

    this.getOrdergroups();
    this.getDepartments();

  }

  /**
   * Initialize new tabel collis
   * @use getColliModel()
   * @see http://tabulator.info/
   * @param order_id
   * @param langs
   */
  async newTableCollis(order_id = 0, langs = {}) {

    let me = this;
    let urlParams = {
      filter: JSON.stringify([{"property": "order_id", "value": order_id}])
    };

    //create Tabulator with getColliModel()
    this.tableCollis = new Tabulator(this.divColliTable, {
      clipboard: true,
      langs: langs,
      maxHeight: 250,
      movableRows: false,
      resizableColumns: false,
      movableColumns: false,
      layout: "fitDataFill", //fit columns to width of table (optional)
      //selectable: true,
      ajaxConfig: {
        method: "GET",
        headers: {
          'Accept': 'application/json , text/plain, */*',
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'include'
      },
      ajaxParams: urlParams,
      ajaxURL: this.config.restOrderCollis,
      ajaxResponse: function (url, params, response) {
        let data = [];
        let responseData = response.data;
        Object.keys(responseData).forEach((item, index) => {
          data.push(responseData[item])
        });
        return data; //return the tableData property of a response json object
      },
      persistenceMode: 'local',
      persistentLayout: false,
      persistentSort: true,
      columns: await this.getColliModel(),
      validationFailed: function (cell, value, validators) {

      },
      rowAdded: async function (row) {

      },
      cellEditing: function (cell) {

      },
      cellEdited: function (cell) {

        let data = cell.getData();
        me.updateCollis(data)
      },
      rowUpdated: function (row) {

      },
      rowDeleted: function (row) {

      },
      dataLoaded: function (data) {

      },
      tableBuilt: function () {
      },
    });
    this.tableCollis.setLocale(this.config.defaultLanguage);

    if (this.order_id > 0) {
      let elem = document.getElementById('colli-table');
      if (elem) {
        elem.append(this.divColliTable)
      }
    }
  }

  /**
   * get wares
   */
  async getWares() {
    let me = this;
    try {
      if (typeof this.appData === 'object') {
        me.wares = this.appData['wares']['data'];

        me.wares.forEach(function (item, index) {
          // @ts-ignore
          me.keyValuesOriginalWare.push('"' + encodeURIComponent(encodeURI(item['warecode'])) + '":"' + item['description'].replace('"', "&quot;") + '"');
          // @ts-ignore
          me.keyValuesWare.push('"' + encodeURIComponent(encodeURI(item['warecode'])) + '":"' + encodeURI(item['warecode']) + ' - ' + item['description'].replace('"', "&quot;") + '"');
        });
        me.jsonElementsKeyValueOriginal = JSON.parse("{" + me.keyValuesOriginalWare.join(",") + "}");
        me.jsonElementsKeyValue = JSON.parse("{" + me.keyValuesWare.join(",") + "}");
        return me.jsonElementsKeyValue
      }

    } catch (e) {
      console.error(e)
    }
  };

  /**
   * Get commodities
   */
  async getCommodities() {
    let commodities: any;
    await this.commoditiesService.getCommodities()
      .then((res) => {
        commodities = res;
      });
    return commodities;
  }

  /**
   * get colli model, column def
   */
  async getColliModel() {
    let me = this;
    //get commodities
    let commodities: any = [];
    commodities = await this.getCommodities();

    //build select box data
    let objCommodities = {};
    let objRelationToWare = {};
    let temp = [];
    let tempRelationToWare = [];
    try {
      if (commodities.data) {
        commodities.data.forEach((data) => {

          temp.push('"' + data.code + '":"' + data.code + ' - ' + data.description + '"');
          tempRelationToWare.push('"' + data.code + '":' + data.ware_id)

        });
      }
      if (temp.length > 0) {
        let listToString = temp.join(",");
        listToString = listToString.replace(/\\/g,'/');// remove backslash
        let strCommodities = '{' +listToString + '}';
        objCommodities = JSON.parse(strCommodities);
        objRelationToWare = JSON.parse('{' + tempRelationToWare.join(",").replace(/\\/g,'/') + '}');// remove backslash
      }

    } catch (e) {
      console.error(e)
    }

    //get profile if customer exists
    if (me.customer_id.toString().length > 0) {
      let customerData = me.jsonGetCustomerById(parseInt(me.customer_id));
      if (typeof customerData !== 'undefined') {
        let data = me.jsonGetProfilesById(customerData.profile_id);
        let profileCollis = Object.assign({}, data);
        delete profileCollis['Profile'];
        me.profile = data['Profile'];
        me.profile['profile_collis'] = profileCollis;
      }

    }
    /**
     * Validator height
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorHeight = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }
      if (profile.require_height === true && (value == '' || value <= 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator ldm
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorLdm = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_ldm === true && (value == '' || value === 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator length
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorLength = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_length === true && (value == '' || value <= 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator temp
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorTemp = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_temp === true && (value == '' || value === 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator vol
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorVol = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_vol === true && (value == '' || value === 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator weight
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorWeight = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_weight === true && (value == '' || value === 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };

    /**
     * Validator width
     * @param cell
     * @param value
     * @param parameters
     */
    let validatorWidth = function (cell, value, parameters) {
      let returnValidation = false;
      let codeValue = cell.getRow().getCell('code').getValue();
      let profile: any;
      if (codeValue > '') {
        profile = me.jsonGetProfileByCode(codeValue);
      }

      if (profile.require_width === true && (value == '' || value === 0 || value === null)) {
        returnValidation = false;
      } else {
        returnValidation = true;
      }
      return returnValidation;
    };
    let unitConversions=[];
    if(this.appData.unitConversions.data.length > 0){
      unitConversions = this.appData.unitConversions.data;
    }



    /**
     * Model colli
     */
    let modelColli = [
      {
        width: 40,
        headerSort: false,
        formatter: function (cell) {
          let row = cell.getRow();
          let value = '<i style="font-size: 1.5em; margin: 3px 0 0 3px; cursor: pointer; " class="fa fa-trash text-danger"></i>';
          return value;
        },
        cellClick: function (e, cell) {
          let data = cell.getData();

          cell.setValue();
          let confirmDelete = confirm('Delete');
          if (confirmDelete === true) {
            me.removeColli(data.id)
          }
        }
      },
      {
        title: "mrk",
        field: "mrk",
        editor: 'input',
        visible: me.profile.enable_mrk,
        headerSort: false,
        validator: ""
      },
      {
        title: "Commodity",
        field: "commodity_code",
        headerSort: false,
        visible: me.profile.enable_commodity_code,
        editor: "autocomplete",
        editorParams: {
          showListOnEmpty: true,
          freetext: true,
          allowEmpty: true,
          values: objCommodities
        },
        cellEdited: function (cell) {
          let row = cell.getRow();
          let table = cell.getTable();
          let code = cell.getValue();
          let valueWeight = row.getCell('weight').getValue();
          let commodity={};
          commodity = me.jsonGetCommodityByCode(code,commodities.data);
          if (objRelationToWare.hasOwnProperty(cell.getValue())) {
            let wareData = me.jsonGetWareById(objRelationToWare[cell.getValue()]);
            if (wareData['warecode']) {
              row.update({ware_code: wareData['warecode']})
            }
            if(commodity['description']){
              row.update({description: commodity['description']})
            }
          }
          //calculate kg to vol using conversion_factor
          let volume = row.getCell('vol');
          let commodityData = me.jsonGetCommodityByCode(code,commodities.data);
          let newVolume = null;

          if(commodityData['conversion_factor'] !== null) {
            newVolume = valueWeight / (parseInt(commodityData['conversion_factor']))
            volume.setValue(newVolume.toFixed(2))
          }
        }
      },
      {
        title: "ware",
        field: "ware_code",
        visible: me.profile.enable_warecode,
        editor: "autocomplete",
        editorParams: {
          showListOnEmpty: true,
          freetext: true,
          allowEmpty: true,
          values: me.jsonElementsKeyValue,

        },
        minWidth: 100,
        headerSort: false,
        formatter: function (cell) {

          //get value from input
          let value = "";
          value = cell.getValue();

          //get description cell
          let cellDescription = cell.getRow().getCell('description');
          let objCellDescription = cellDescription._cell;
          if (objCellDescription) {
            if (me.jsonElementsKeyValueOriginal[value] != undefined) {
              objCellDescription.setValue(me.jsonElementsKeyValueOriginal[value].replace("&quot;", '"'))
            }

          }

          //if not defined clear input
          if (value === undefined || value === null || value === "undefined") {
            cell.setValue('');
            value = "";
          }
          return decodeURI(value);
        }

      },
      {
        title: "description",
        field: "description",
        validator: ["required"],
        editor: 'input',
        visible: true,
        headerSort: false,
        minWidth: 250,
      },
      {
        title: "count",
        field: "count",
        minWidth: 50,
        editor: "number",
        editorParams: {
          min: 1
        },

        formatter: function (cell) {
          let defaultValue;
          if (cell.getValue() === undefined || cell.getValue() === "") {
            defaultValue = 1;
            cell.setValue(defaultValue)
          } else {
            defaultValue = cell.getValue()
          }

          return defaultValue;
        },
        visible: true,
        headerSort: false,
        cellEdited:function(cell){
          let row = cell.getRow();
          let value = cell.getValue();
          let cellUnit = row.getCell('code').getValue();
          if(unitConversions.length > 0){
            try{
              unitConversions.forEach((item)=>{
                //if unit conversion exists for this unit, calculate and update value
                if(item.code === cellUnit){
                  //calculate and update  bund_pll
                  if(item.bund_pll > 0){
                    row.getCell('bund_pll').setValue( parseInt(row.getCell('count').getValue()) * parseInt(item.bund_pll));
                  }
                  if(item.height > 0){
                    row.getCell('height').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.height));
                  }
                  if(item.width > 0){
                    row.getCell('width').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.width));
                  }
                  if(item.ldm > 0){
                    row.getCell('ldm').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.ldm));
                  }
                  if(item.length > 0){
                    row.getCell('length').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.length));
                  }
                  if(item.pll > 0){
                    row.getCell('pll').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.pll));
                  }
                  if(item.swap_pallets > 0){
                    row.getCell('swap_pallets').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.swap_pallets));
                  }
                  if(item.volume > 0){
                    row.getCell('vol').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.volume));
                  }
                  if(item.weight > 0){
                    row.getCell('weight').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.weight));
                  }

                }
              })

            }catch (e) {
              console.error(e)
            }
          }



        }
      },
      {
        title: "code",
        field: "code",
        minWidth: 100,
        validator: ["required"],
        editor: "select",
        editorParams: {
          showListOnEmpty: true,
          defaultValue: '',
          freetext: false,
          allowEmpty: true,
          values: this.jsonElementsKeyValueProfileColli
        },
        formatter: function (cell) {
          //get value from input
          let value = "";
          value = cell.getValue();

          //if not defined clear input
          if (value === undefined || value === null || value === "undefined" || value === '') {
            value = "";
          }
          return decodeURI(value);
        },
        visible: true,
        headerSort: false,
        cellEdited:function(cell){
          let row = cell.getRow();
          let value = cell.getValue();
          let cellUnit = row.getCell('code').getValue();
          if(unitConversions.length > 0){
            try{
              unitConversions.forEach((item)=>{
                //if unit conversion exists for this unit, calculate and update value
                if(item.code === cellUnit){
                  //calculate and update  bund_pll
                  if(item.bund_pll > 0){
                    row.getCell('bund_pll').setValue( parseInt(row.getCell('count').getValue()) * parseInt(item.bund_pll));
                  }
                  if(item.height > 0){
                    row.getCell('height').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.height));
                  }
                  if(item.width > 0){
                    row.getCell('width').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.width));
                  }
                  if(item.ldm > 0){
                    row.getCell('ldm').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.ldm));
                  }
                  if(item.length > 0){
                    row.getCell('length').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.length));
                  }
                  if(item.pll > 0){
                    row.getCell('pll').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.pll));
                  }
                  if(item.swap_pallets > 0){
                    row.getCell('swap_pallets').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.swap_pallets));
                  }
                  if(item.volume > 0){
                    row.getCell('vol').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.volume));
                  }
                  if(item.weight > 0){
                    row.getCell('weight').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.weight));
                  }

                }
              })

            }catch (e) {
              console.error(e)
            }
          }

        }
      },
      {
        title: "weight",
        field: "weight",
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 1,
          step: 1
        },
        validator: ["min:0.01"],
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        formatterParams: {
          precision: 2,
        },
        visible: me.profile.show_weight,
        headerSort: false,
        cellEdited:function(cell){
          let row = cell.getRow();
          let valueWeight = cell.getValue();
          let cellWeightInTon = row.getCell('weight_in_ton');
          cellWeightInTon.setValue((valueWeight / 1000).toFixed(2));

          //calculate kg to vol using conversion_factor
          let volume = row.getCell('vol'); // get cell vol
          let code = row.getCell('commodity_code').getValue(); // get commodity data
          let commodityData = me.jsonGetCommodityByCode(code,commodities.data); // get commodity by code
          let newVolume = null;
          if(commodityData['conversion_factor'] !== null){
            newVolume = valueWeight / (parseInt(commodityData['conversion_factor']))
            volume.setValue(newVolume.toFixed(2))
          }

        }
      },
      {
        title: "weight_in_ton",
        field: "weight_in_ton",
        minWidth: 80,
        editor: 'number',
        editorParams: {
          min: 0.01,
          step: 0.1
        },
        formatterParams: {
          precision: 2,
        },
        visible: me.profile.show_weight_in_ton,
        headerSort: false,
        cellEdited:function(cell){
          let row = cell.getRow();
          let value = cell.getValue();
          let cellWeightInKg = row.getCell('weight');
          cellWeightInKg.setValue((value * 1000).toFixed(2))

        }

      },
      {
        title: "length",
        field: "length",
        validator: [{type: validatorLength}],
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 0,
          step: 1
        },
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        visible: me.profile.allow_colli_l,
        headerSort: false,
      },
      {
        title: "width",
        field: "width",
        validator: [{type: validatorWidth}],
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 0,
          step: 1
        },
        visible: me.profile.allow_colli_w,
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        headerSort: false,
      },
      {
        title: "height",
        field: "height",
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 0,
          step: 1
        },
        validator: [{type: validatorHeight}, "min:0.01"],
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        formatterParams: {
          precision: 2,
        },
        visible: me.profile.allow_colli_h,
        headerSort: false,
      },
      {
        title: "code_text",
        field: "code_text",
        editor: 'input',
        visible: false,
        headerSort: false,
      },
      {
        title: "vol",
        field: "vol",
        validator: [{type: validatorVol}],
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 0,
          step: 1
        },
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        visible: me.profile.show_m3,
        headerSort: false

      },
      {
        title: "ldm",
        field: "ldm",
        validator: [{type: validatorLdm}],
        minWidth: 60,
        editor: 'number',
        editorParams: {
          min: 0,
          step: 1
        },
        visible: me.profile.allow_colli_ldm,
        formatter: function (cell) {
          let value = cell.getValue();
          if (value < 0) {
            value = 0;
            cell.setValue(value)
          }
          let defaultValue;
          if (value == undefined || value == "" || value == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = value
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return '';
          }

        },
        headerSort: false,
      },
      {
        title: "pll",
        field: "pll",
        editor: 'input',
        visible: me.profile.show_pll,
        headerSort: false,
      },
      {
        title: "bund_pll",
        field: "bund_pll",
        editor: 'input',
        visible: me.profile.show_pll,
        headerSort: false,
      },
      {
        title: "swap_pallets",
        field: "swap_pallets",
        editor: 'number',
        editorParams: {
          min: 0
        },
        validator: "min:0",
        formatter: function (cell) {
          let defaultValue;
          if (cell.getValue() === undefined || cell.getValue() === "") {

            defaultValue = 0;

          } else {
            defaultValue = cell.getValue()
          }
          if (defaultValue < 0) {
            cell.setValue(0);
            defaultValue = 0;
          }


          return parseFloat(defaultValue).toFixed(0);
        },
        visible: me.profile.profile_collis.pallet_exchange,
        headerSort: false,
      },
      {
        title: "distance",
        field: "distance",
        editor: 'input',
        visible: true,
        headerSort: false,
      },
      {
        title: "work_hours",
        field: "work_hours",
        editor: 'input',
        visible: false,
        headerSort: false,
      },
      {
        title: "temp",
        headerSort: false,
        field: "temp",
        formatter: function (cell) {
          let defaultValue;
          if (cell.getValue() == undefined || cell.getValue() == "" || cell.getValue() == 0.00) {
            defaultValue = null;
          } else {
            defaultValue = cell.getValue()
          }
          if (defaultValue !== null) {
            return parseFloat(defaultValue).toFixed(2);
          } else {
            return null;
          }

        },
        validator: [{type: validatorTemp}],
        editor: 'input',
        visible: me.profile.allow_temp,
      },
      {
        title: "uc",
        field: "uc",
        align: "center",
        editor: 'tickCross',
        formatter: "tickCross",
        visible: false,
        headerSort: false,
      },
      {
        title: "calc_price",
        field: "calc_price",
        editor: 'input',
        visible: false,
        headerSort: false,
      },
      {
        title: "unit_price",
        field: "unit_price",
        editor: 'input',
        visible: false,
        headerSort: false,
      },
      {
        title: "price_warning",
        field: "price_warning",
        editor: 'input',
        visible: false,
        headerSort: false,
      },
      {
        title: "cold",
        field: "cold",
        align: "center",
        formatter: "tickCross",
        editor: 'tickCross',
        visible: me.profile.enable_cold,
        headerSort: false,
      },
      {
        title: "freezing",
        field: "freezing",
        align: "center",
        editor: 'tickCross',
        formatter: "tickCross",
        visible: me.profile.enable_freezing,
        headerSort: false,
      },
      // {
      //   title: "used_taksttype",
      //   field: "used_taksttype",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "fp_weight",
      //   field: "fp_weight",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "un_number",
      //   field: "un_number",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "class",
      //   field: "class",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "packing_group",
      //   field: "packing_group",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "classification_code",
      //   field: "classification_code",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "tunnel_restriction_code",
      //   field: "tunnel_restriction_code",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "adr_tank_code",
      //   field: "adr_tank_code",
      //   editor: 'input',
      //   visible: true,
      //   headerSort: false,
      // },
      // {
      //   title: "haz_description",
      //   field: "haz_description",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "oil_fee",
      //   field: "oil_fee",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "no_department",
      //   field: "no_department",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "priority",
      //   field: "priority",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "extra_fields",
      //   field: "extra_fields",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "commodity_code",
      //   field: "commodity_code",
      //   editor: 'input',
      //   visible: true,
      //   headerSort: false,
      // },
      // {
      //   title: "tax_rate",
      //   field: "tax_rate",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "trailer_temp_type",
      //   field: "trailer_temp_type",
      //   editor: 'input',
      //   visible: true,
      //   headerSort: false,
      // },
      // {
      //   title: "alert_info",
      //   field: "alert_info",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "line_number",
      //   field: "line_number",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "label_count",
      //   field: "label_count",
      //   editor: 'input',
      //   visible: true,
      //   headerSort: false,
      // },
      // {
      //   title: "currency",
      //   field: "currency",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "main_colli_id",
      //   field: "main_colli_id",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "updates_waiting_for_approval",
      //   field: "updates_waiting_for_approval",
      //   visible: false,
      //   editor: 'input',
      //   headerSort: false,
      // },
      // {
      //   title: "invoice_line_reference",
      //   field: "invoice_line_reference",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "created_at",
      //   field: "created_at",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      // },
      // {
      //   title: "updated_at",
      //   field: "updated_at",
      //   editor: 'input',
      //   visible: false,
      //   headerSort: false,
      //   width: 30,
      //   minWidth: 30,
      // },
      // {
      //   title: '',
      //   field: 'extraSwapPallets',
      //   headerSort: false,
      //   formatter: function(cell, formatterParams, onRendered){
      //     let url = document.location.origin;
      //     // get data from row
      //     let val = cell.getData();
      //     let position = cell.getRow().getPosition()
      //
      //     return '<div style="text-align: center" onclick="ObjControllerPopupsEditSwapPallets.open(' + val.id + ','+ val.order_id +','+ position +' )"><img id="icon-swap-pallets" alt="swap pallets" style="margin-left: auto; margin-right: auto" src="' + url + '/images/lorry_go.png"></div>';
      //   },
      //   visible: true
      //
      // },
      // {
      //   title: " ",
      //   field: "delete",
      //   align: 'right',
      //   headerSort: false,
      //   resizable: false,
      //   width: 30,
      //   minWidth: 30,
      //   formatter: function () {
      //     return '<div style="text-align: center" ><i class="far fa-trash-alt"></i></div>';
      //   },
      //   cellClick: function (e, cell) {
      //     var row = cell.getRow();
      //     var rowPos = row.getPosition();
      //   }
      // },
      // {
      //   field: "SwapPallets",
      //   visible: false,
      // }
    ];
    this.collisTableDefinition = modelColli;
    return modelColli;
  }

  /**
   * Set form data order to template
   */
  setFormOrder(orderData) {
    try {

      //add pickup dselivery time
      orderData['pickup_time'] = '08:00';
      orderData['delivery_time'] = '16:00';

      for (let orderDataKey in orderData) {
        if (orderData[orderDataKey] === null || orderData[orderDataKey] === undefined) {
          //remove undefined data
          delete orderData[orderDataKey];
        } else {
          //fix pickup and delivery date
          if (orderDataKey === 'pickup_date') {
            orderData['pickup_time'] = moment(orderData[orderDataKey]).format('HH:mm');
            orderData[orderDataKey] = moment(orderData[orderDataKey]).format('DD-MM-YYYY');
          }
          if (orderDataKey === 'delivery_date') {
            orderData['delivery_time'] = moment(orderData[orderDataKey]).format('HH:mm');
            orderData[orderDataKey] = moment(orderData[orderDataKey]).format('DD-MM-YYYY');
          }
          if (this.formEditOrder.controls[orderDataKey]) {
            this.formEditOrder.controls[orderDataKey].setValue(orderData[orderDataKey])
          }
        }
      }
      //select box data

      this.customer_id = orderData.customer_id;
      this.from_country = orderData.from_country;
      this.to_country = orderData.to_country;
      this.autofleet_vehicle = orderData.autofleet_vehicle;
      this.contract_number = orderData.contract_number;
      this.group = orderData.group;
      this.department_id = orderData.department_id;
      //get customer specifications data
      this.getCustomerSpecifications(this.customer_id);
      this.setIconHome(this.customer_id.toString())

    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Handle order form submit
   * @param event
   */
  onSubmit(event) {

    this.backdropEditOrder = true;
    let me = this;
    let orderToUpdate = this.formEditOrder.value;
    //fix date before saving
    let tempPickup = this.formEditOrder.controls.pickup_date.value.split('-');
    let tempDelivery = this.formEditOrder.controls.delivery_date.value.split('-');
    orderToUpdate['pickup_date'] = moment(new Date(tempPickup[2] + '-' + tempPickup[1] + '-' + tempPickup[0] + ' ' + this.formEditOrder.controls.pickup_time.value)).format();
    orderToUpdate['delivery_date'] = moment(new Date(tempDelivery[2] + '-' + tempDelivery[1] + '-' + tempDelivery[0] + ' ' + this.formEditOrder.controls.delivery_time.value)).format();

    if (this.order_id > 0) {
      this.orderService.updateOrder(orderToUpdate, this.order_id, 1)
        .subscribe(function (res) {
          me.backdropEditOrder = false;
          me.router.navigate(['/orders-tab'])
        },function (err) {
          for (let errKey in err) {
            console.error(err[errKey]);
          }
          alert(err.statusText + "\n" + err.message + "\n\n" + me.getLanguageVars('try_again', me.language));
          me.backdropEditOrder = false;
        });
    } else {
      me.backdropEditOrder = false;
    }
  }

  /**
   * Get data from select box customer
   * @param event
   * @param selectBoxCustomer
   */
  onSelectBoxCustomerValueChange(event, selectBoxCustomer) {
    let id = '';
    if (typeof event === 'string') {
      id = event
    } else if (typeof event === 'object') {
      id = event.value
    }
    //enable backdrop
    this.backdropEditOrder = true;
    this.customer_id = id;
    //get customer specification
    this.getCustomerSpecifications(this.customer_id);

    this.formEditOrder.controls.customer_id.setValue(this.customer_id);

    let currentCustomer = {};
    // get customer data
    this.customers.getById(id, (res) => {
      currentCustomer = res.data;

      //get column code data to build select box
      let profiles = this.appData.profiles.data;
      //get profile data
      let customerProfileData = [];

      profiles.forEach((item) => {
        if (currentCustomer['profile_id'] == item['profile_id']) {
          customerProfileData.push(item);
          return;
        }
      });

      let selecBoxData = {};
      customerProfileData.forEach((item, index) => {
        let id = item.Unit['code'];
        selecBoxData[id] = item.Unit['description'];
      });
      this.jsonElementsKeyValueProfileColli = selecBoxData;
      if (this.customer_id) {
        let orderToUpdate = this.formEditOrder.value;
        //fix date before saving
        let tempPickup = this.formEditOrder.controls.pickup_date.value.split('-');
        let tempDelivery = this.formEditOrder.controls.delivery_date.value.split('-');
        orderToUpdate['pickup_date'] = moment(new Date(tempPickup[2] + '-' + tempPickup[1] + '-' + tempPickup[0] + ' ' + this.formEditOrder.controls.pickup_time.value)).format();
        orderToUpdate['delivery_date'] = moment(new Date(tempDelivery[2] + '-' + tempDelivery[1] + '-' + tempDelivery[0] + ' ' + this.formEditOrder.controls.delivery_time.value)).format();
        orderToUpdate['invoice_customer_id'] = parseInt(currentCustomer['id']);


        this.orderService.updateOrder(orderToUpdate, this.order_id, 2)
          .toPromise()
          .then((res) => {
            this.newTableCollis(this.order_id);
            this.backdropEditOrder = false;
          })
          .catch(() => {
            this.backdropEditOrder = false;
          })

        this.setIconHome(this.customer_id)
      }
    })
  }

  /**
   * Customer specifications change
   * @param event
   */
  selectBoxCustomerSpecificationsValueChange(event) {
    let contract_number='';
    if(typeof event === 'string'){
      contract_number= event
    } else if(typeof event === 'object'){
      contract_number= event.value
    }
    this.contract_number = contract_number;
    this.formEditOrder.controls.contract_number.setValue(parseInt(this.contract_number));
  }

  /**
   * Frome country change
   * @param event
   */
  selectBoxFromCountryValueChange(event) {
    let from_country='';
    //!! A newer version of the select box library may return a different value in 'event'
    if(typeof event === 'string'){
      from_country= event
    } else if(typeof event === 'object'){
      from_country= event.value
    }
    this.formEditOrder.controls.from_country.setValue(from_country);
  }

  /**
   * @param event
   */
  selectBoxToCountryValueChange(event) {
    let to_country='';
    //!! A newer version of the select box library may return a different value in 'event'
    if(typeof event === 'string'){
      to_country= event
    } else if(typeof event === 'object'){
      to_country= event.value
    }
    this.formEditOrder.controls.to_country.setValue(to_country);
  }

  /**
   *
   * @param event
   */
  selectBoxVehicleValueChange(event) {
    let autofleet_vehicle='';
    //!! A newer version of the select box library may return a different value in 'event'
    if(typeof event === 'string'){
      autofleet_vehicle= event
    } else if(typeof event === 'object'){
      autofleet_vehicle= event.value
    }
    this.formEditOrder.controls.autofleet_vehicle.setValue(autofleet_vehicle);
  }

  selectBoxGroup(event){
    this.group = event.value;
    this.formEditOrder.controls.group.setValue(event.value);
  }

  selectBoxDepartmentId(event){
    this.department_id = event.value;
    this.formEditOrder.controls.department_id.setValue(event.value);
  }

  /**
   * Set form inputs on address change
   * @param event from input
   */
  onChangeFromLocationSearch(event) {

    let streetNumber;
    let street;
    let city;
    let zipcode;
    let country;

    for (let i = 0; i < event.address_components.length; i++) {

      let addressType = event.address_components[i].types[0];
      if (addressType === "street_number") {
        streetNumber = event.address_components[i].short_name;
      }
      if (addressType === "route") {
        this.formEditOrder.controls.from_address1.setValue('');
        street = event.address_components[i].long_name + ' ' + streetNumber;
        this.formEditOrder.controls.from_address1.setValue(street);
        if (event.address_components[i].long_name.trim() === event.name.trim() || street.trim() === event.name.trim()) {
          this.formEditOrder.controls.from_address1.setValue('');
        } else {
          if (event.name) {
            this.formEditOrder.controls.from_name.setValue(event.name);
          }
        }
      }
      if (addressType === "locality") {
        city = event.address_components[i].long_name;
        this.formEditOrder.controls.from_city.setValue(city);
      }
      if (addressType === "postal_code") {
        zipcode = event.address_components[i].long_name;
        this.formEditOrder.controls.from_zipcode.setValue(zipcode);

      }
      if (addressType === "country") {
        this.from_country = event.address_components[i].short_name;
        this.formEditOrder.controls.from_country.setValue(this.from_country)
      }

    }

    this.formEditOrder.controls.search_from_location.setValue('');
  }

  /**
   * Set form inputs on address change
   * @param event from input
   */
  onChangeToLocationSearch(event) {

    let streetNumber;
    let street;
    let city;
    let zipcode;
    let country;

    for (let i = 0; i < event.address_components.length; i++) {

      let addressType = event.address_components[i].types[0];
      if (addressType === "street_number") {
        streetNumber = event.address_components[i].short_name;
      }
      if (addressType === "route") {
        this.formEditOrder.controls.to_address1.setValue('');
        street = event.address_components[i].long_name + ' ' + streetNumber;
        this.formEditOrder.controls.to_address1.setValue(street);
        if (event.address_components[i].long_name.trim() === event.name.trim() || street.trim() === event.name.trim()) {
          this.formEditOrder.controls.to_address1.setValue('');
        } else {
          if (event.name) {
            this.formEditOrder.controls.to_name.setValue(event.name);
          }
        }
      }
      if (addressType === "locality") {
        city = event.address_components[i].long_name;
        this.formEditOrder.controls.to_city.setValue(city);
      }
      if (addressType === "postal_code") {
        zipcode = event.address_components[i].long_name;
        this.formEditOrder.controls.to_zipcode.setValue(zipcode);

      }
      if (addressType === "country") {
        this.to_country = event.address_components[i].short_name;
        this.formEditOrder.controls.to_country.setValue(this.to_country)
      }

    }

    this.formEditOrder.controls.search_to_location.setValue('');
  }

  /**
   * set column select box data
   * @param reloadTable
   * @param callback
   */
  setColumnSelectBoxData(reloadTable = false, callback) {
    let me = this;
    //get column code data to build select box
    let profiles = this.appData.profiles.data;
    let currentCustomer = this.customer_id;

    if (currentCustomer > "0") {
      //get profile data
      let customerProfileData = [];
      profiles.forEach((item) => {
        if (this.orderData.Customer.profile_id == item['profile_id']) {
          customerProfileData.push(item);
          return;
        }
      });
      let selecBoxData = {};

      customerProfileData.forEach((item, index) => {
        let id = item.Unit['code'];
        selecBoxData[id] = item.Unit['description'];
      });

      me.jsonElementsKeyValueProfileColli = selecBoxData;
      if (reloadTable === true) {
        this.newTableCollis(this.order_id)
      }
    }
    if (reloadTable === false && callback === 'function') {
      callback(this.jsonElementsKeyValueProfileColli);
    }

  }

  //template vars
  getLanguageVars(arg, lang) {
    if (lang != undefined) {
      if (arg in lang) {
        return lang[arg]
      } else {
        return '...'
      }
    } else {
      return '...'
    }
  }

  /**
   * Show/hide datepicker pickup
   * @param event
   */
  toggleDatepickerPickup(event) {
    this.showDatepickerPickup = !this.showDatepickerPickup;
  }

  /**
   * Show/hide datepicker delivery
   * @param event
   */
  toggleDatepickerDelivery(event) {
    this.showDatepickerDelivery = !this.showDatepickerDelivery;
  }

  /**
   * Datepicker function
   * @param date
   */
  onDatePickerChangePickup(date) {
    let newDate = moment(date.year + '-' + date.month + '-' + date.day).format('DD-MM-YYYY').toString();
    this.formEditOrder.controls.pickup_date.setValue(newDate);
    this.showDatepickerPickup = !this.showDatepickerPickup;
  }

  /**
   * Datepicker function
   * @param date
   */
  onDatePickerChangeDelivery(date) {
    let newDate = moment(date.year + '-' + date.month + '-' + date.day).format('DD-MM-YYYY').toString();
    this.formEditOrder.controls.delivery_date.setValue(newDate);
    this.showDatepickerDelivery = !this.showDatepickerDelivery;
  }

  /**
   * Add colli to order
   */
  addColli() {
    this.backdropTabelCollis = true;
    let newColli = this.orderService.addColli({order_id: this.order_id, count: 1, height: null})
      .then((response) => {
        //if unit conversions get them and set values
       try{
         let data = response['data'];

         let unitConversions=[];
         if(this.appData.unitConversions.data.length > 0){
           unitConversions = this.appData.unitConversions.data;
         }
         unitConversions.forEach((item)=>{
           if(item.code === data.code){
             if(item.bund_pll.length > 0){
               data.bund_pll = (parseInt(data.count)*parseInt(item.bund_pll));
             }
             if(item.height.length > 0){
               data.height = (parseInt(data.count)*parseInt(item.height));
             }
             if(item.ldm.length > 0){
               data.ldm = (parseInt(data.count)*parseInt(item.ldm));
             }
             if(item.length.length > 0){
               data.length = (parseInt(data.count)*parseInt(item.length));
             }
             if(item.pll.length > 0){
               data.pll = (parseInt(data.count)*parseInt(item.pll));
             }
             if(item.swap_pallets.length > 0){
               data.swap_pallets = (parseInt(data.count)*parseInt(item.swap_pallets));
             }
             if(item.volume.length > 0){
               data.vol = (parseInt(data.count)*parseInt(item.volume));
             }
             if(item.weight.length > 0){
               data.weight = (parseInt(data.count)*parseInt(item.weight));
             }
             if(item.width.length > 0){
               data.width = (parseInt(data.count)*parseInt(item.width));
             }
           }
         });
         // @ts-ignore
         this.tableCollis.addRow(response.data);
         this.backdropTabelCollis = false;
       }catch (e) {
         console.error(e)
       }

      })
      .catch(error => console.error(error.json()));
  }

  /**
   * Remove colli
   * @param id
   */
  removeColli(id) {
    this.backdropTabelCollis = true;
    this.orderService.removeColli(id)
      .then((res) => {
        this.tableCollis.setData();
        this.backdropTabelCollis = false;
      })
      .catch(() => {
        this.backdropTabelCollis = false;
        console.error('Error while remove colli');
      })
  }

  /**
   *
   * @param collisData
   */
  updateCollis(collisData) {
    let newColli = this.orderService.updateColli(collisData)
      .then((response) => {
      })
      .catch(error => console.error(error));
  }


  setTodayToPickupDate() {
    this.formEditOrder.controls.pickup_date.setValue(this.today);
    this.showDatepickerPickup = !this.showDatepickerPickup;
  }

  /**
   * Datepicker function
   */
  setTodayToDeliveryDate() {
    this.formEditOrder.controls.delivery_date.setValue(this.today);
    this.showDatepickerDelivery = !this.showDatepickerDelivery;
  }

  /**
   * Get customer specifications by customer id
   * @param customerId
   */
  getCustomerSpecifications(customerId) {
    this.customerSpecificatonsService.getCustomerSpecifationsByCustomerId(customerId)
      .then((res) => {
        let newCustomerSpecs = [];

        res['data'].forEach(function (item, index) {

          newCustomerSpecs[index] = {id: item['contract_number'], text: item['contract_number']};
        });

        this.customerSpecificationsData = newCustomerSpecs;
      })
  }

  /**
   * Set Customer to form
   */
  setCustomerToPickupForm(){
    let customer = this.jsonGetCustomerById(parseInt(this.customer_id));
    if(customer){
      this.formEditOrder.controls.from_name.setValue(customer.name);
      this.formEditOrder.controls.from_address1.setValue(customer.address1);
      this.formEditOrder.controls.from_city.setValue(customer.city);
      this.formEditOrder.controls.from_zipcode.setValue(customer.zipcode);
      this.from_country  = customer.country;
    }
  }

  /**
   * Set cutomer to form
   */
  setCustomerToDeliveryForm(){
    let customer = this.jsonGetCustomerById(parseInt(this.customer_id));
    if(customer){
      this.formEditOrder.controls.to_name.setValue(customer.name);
      this.formEditOrder.controls.to_address1.setValue(customer.address1);
      this.formEditOrder.controls.to_city.setValue(customer.city);
      this.formEditOrder.controls.to_zipcode.setValue(customer.zipcode);
      this.to_country  = customer.country;
    }
  }

  /**
   * Validate order form
   */
  validationNewOrder() {
    this.errorMsg = [];
    if (this.customer_id < "1" || this.customer_id === undefined) {
      this.errorMsg.push('Please  choose a customer');
    }
    return this.errorMsg.length <= 0;
  }

  /**
   * Get ware by id
   * @param id
   */
  jsonGetProfilesById(id: number = 0) {
    let returnData = [];
    let profiles;
    if (id > 0) {
      try {
        profiles = this.appData['profiles']['data'];
        let i = 1;
        profiles.some((item) => {
          if (id === item.profile_id) {
            returnData = item;
            return true;
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    return returnData;
  }

  /**
   * Get profile by code
   * @param code
   */
  jsonGetProfileByCode(code: string = '') {
    let returnData = [];
    let profiles;
    if (code > '') {
      try {
        profiles = this.appData['profiles']['data'];
        let i = 1;
        profiles.some((item) => {
          if (code === item.code) {
            returnData = item;
            return true;
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    return returnData;
  }

  /**
   * Get ware by id
   * @param id
   */
  jsonGetWareById(id: number = 0) {
    let returnData = [];
    let wares;
    if (id > 0) {
      try {
        wares = this.appData['wares']['data'];
        let i = 1;
        wares.some((item) => {
          if (id === item.id) {
            returnData = item;
            return true;
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    return returnData;
  }

  /**
   * Get ware by id
   * @param code
   * @param data
   */
  jsonGetCommodityByCode(code = '', data) {
    let returnData = [];
    let commodies;
    if (code.length > 0) {
      try {
        commodies = data;
        let i = 1;
        commodies.some((item) => {
          if (code === item.code) {
            returnData = item;
            return true;
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    return returnData;
  }

  /**
   * Get customer by id
   * @param id
   */
  jsonGetCustomerById(id: number = 0) {
    let returnData;
    let customers;
    if (id > 0) {

      try {
        customers = this.appData['customers'];
        let i = 1;
        if(customers && customers.length > 0) {
          customers.some((item) => {
            if (id === item.id) {
              returnData = item;
              return true;
            }
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
    customers = null;
    return returnData;
  }

  /**
   * Get addresses for address select box
   */
   getAddresses() {
    //address data
    this.addressService.get()
      .then((res) => {
        this.addressDataAll = res['data']
        if (this.addressDataAll) {
          this.addressData = [];
          for (let addressDataKey in this.addressDataAll) {
            if (this.addressDataAll.hasOwnProperty(addressDataKey)) {
              this.addressData.push({
                id: this.addressDataAll[addressDataKey].id,
                text: this.addressDataAll[addressDataKey].id + ' - ' + this.addressDataAll[addressDataKey].name,
                name: this.addressDataAll[addressDataKey].name,
                address1: this.addressDataAll[addressDataKey].address1,
                zipcode: this.addressDataAll[addressDataKey].zipcode,
                city: this.addressDataAll[addressDataKey].city,
                country: this.addressDataAll[addressDataKey].country

              });
            }
          }
        }

      });
  }

  /**
   * Get ordergroup data
   */
  getOrdergroups(){
    this.ordergroupsService.getOrdergroups()
      .then((res)=>{
        let newOrdergroupsData = [];
        res['data'].forEach(function (item, index) {
          newOrdergroupsData[index] = {
            id: item['name'],
            text: item['name'],
            account_id: item['account_id'],
            code: item['code'],
            tp_id: item['id'],
            color: item['color']
          };
        });
        this.groupData = newOrdergroupsData;
       return newOrdergroupsData;
      })
  }

  /**
   * Get department data
   */
  getDepartments(){
    this.departmentsService.getDepartments()
      .then((res) => {
        let newDepartmentsData = [];
        res['data'].forEach(function (item, index) {
          newDepartmentsData[index] = {
            id: item['id'],
            text: item['name']  + ' - ' +item['description']  + ' - ' +item['city'],
            account_id: item['account_id'],
            code: item['code'],
            addresse1: item['addresse1'],
            addresse2: item['addresse2'],
            zipcode: item['zipcode'],
            city: item['city'],
            country: item['country'],
            location: item['location']
          };
        });
        this.departmentData = newDepartmentsData;
        return newDepartmentsData;
      })
  }


  /**
   *
   * @param event
   * @param element
   */
  onSelectBoxPickupAddressValueChange(event, element) {
    let id='';
    if(typeof event === 'string'){
      id= event
    } else if(typeof event === 'object'){
      id= event.value
    }

    let me = this;
    if(id !==null){
      let address = [];
      this.addressService.getById(id)
        .then((res)=>{
          address = res['data'];


          if (address != null) {
            if (address[0].country === "Danmark") {
              address[0].country = "dk"
            }

            this.formEditOrder.controls.from_address1.setValue(address[0].address1);
            this.formEditOrder.controls.from_city.setValue(address[0].city);
            this.formEditOrder.controls.from_zipcode.setValue(address[0].zipcode);
            this.formEditOrder.controls.from_name.setValue(address[0].name);
            this.formEditOrder.controls.from_country.setValue(address[0].country);
            this.from_country = address[0].country;
          }

          setTimeout(function () {
            element.writeValue('')
          }, 1000)


        });

    }

  }

  /**
   *
   * @param event
   * @param element
   */
  onSelectBoxDeliveryAddressValueChange(event, element) {
    let me = this;
    let id='';
    if(typeof event === 'string'){
      id= event
    } else if(typeof event === 'object'){
      id= event.value
    }
    let address = [];
    if(id !==null){
      this.addressService.getById(id)
        .then((res)=>{
          address = res['data'];
          if (address != null) {
            if (address[0].country === "Danmark") {
              address[0].country = "dk"
            }
            this.formEditOrder.controls.to_address1.setValue(address[0].address1);
            this.formEditOrder.controls.to_city.setValue(address[0].city);
            this.formEditOrder.controls.to_zipcode.setValue(address[0].zipcode);
            this.formEditOrder.controls.to_name.setValue(address[0].name);
            this.to_country  = address[0].country;

            setTimeout(function () {
              element.writeValue('')
            }, 1000)
          }
        })
    }

  }

  /**
   * Set home icon class
   * @param customer_id
   */
  setIconHome(customer_id = ''){

    //check if customer is chosen and set home icon enabled / disabled
    if(customer_id.toString().length > 0){
      this.sidebarIconHome = 'icon-enabled fa fa-home sidebar-icons';
    } else {
      this.sidebarIconHome = 'icon-disabled fa fa-home sidebar-icons';
    }

  }

  /**
   * Update unit
   * @param unit
   * @param row
   */
  updateCellWithUnitCalculation(unit, row){

    let unitConversions=[];
    if(this.appData.unitConversions.data.length > 0){
      unitConversions = this.appData.unitConversions.data;
    }

    if(unitConversions.length > 0){
      try{
        unitConversions.forEach((item)=>{
          //if unit conversion exists for this unit, calculate and update value
          if(item.code === unit){
            //calculate and update  bund_pll
            if(item.bund_pll > 0){
              row.getCell('bund_pll').setValue( parseInt(row.getCell('count').getValue()) * parseInt(item.bund_pll));
            }
            if(item.height > 0){
              row.getCell('height').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.height));
            }
            if(item.width > 0){
              row.getCell('width').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.width));
            }
            if(item.ldm > 0){
              row.getCell('ldm').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.ldm));
            }
            if(item.length > 0){
              row.getCell('length').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.length));
            }
            if(item.pll > 0){
              row.getCell('pll').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.pll));
            }
            if(item.swap_pallets > 0){
              row.getCell('swap_pallets').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.swap_pallets));
            }
            if(item.volume > 0){
              row.getCell('vol').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.volume));
            }
            if(item.weight > 0){
              row.getCell('weight').setValue(parseInt(row.getCell('count').getValue()) * parseInt(item.weight));
            }

          }
        })

      }catch (e) {
        console.error(e)
      }
    }
  }

}

