import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AuthComponent} from "./components/auth/auth.component";
import {EditOrderComponent} from "./components/forms/edit-order/edit-order.component";
import {OrdersTabComponent} from "./components/grids/orders-tab/orders-tab.component";
import {LoaderComponent} from "./components/loader/loader.component";

const appRoutes: Routes = [
  {
    path: '',
    component: AuthComponent
  }, {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'orders-tab',
    component: OrdersTabComponent
  },
  {
    path: 'edit',
    component: EditOrderComponent
  },
  {
    path: 'loader',
    component: LoaderComponent
  }
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ]
})
export class AppRoutingModule {
}
